import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "PublishedCheckbox",
    "Label",
    "DeleteButton",
    "PublishedAtToggleButton",
    "PublishedAtContainer",
    "PublishedAtLabel",
    "PublishedAtInput",
    "PublishedAtPresentMessage",
  ]
  static values = { hasPublishedAt: Boolean }

  initialize() {
    this.meetingId = this.data.get('meetingId')
    this.form = null
  }

  onToggle(event) {
    event.preventDefault();

    var form = $(event.currentTarget).closest('form')
    this.form = form[0]

    if (event.currentTarget.checked) {
      this.updateMeetingPublished(true)
    } else {
      this.updateMeetingPublished(false)
    }
  }

  onDeleteClick(event) {
    event.preventDefault()

    if (window.confirm("Are you sure you want to delete this meeting? This will also delete associated items and attachments.")) {
      event.target.disabled = true
      event.target.innerText = "Deleting..."

      const form = $(event.target).closest('form')
      $.ajax({
        url: $(form).attr('action'),
        method: 'DELETE',
        dataType: "json",
        success: function(json) {
          window.location = "/admin/meetings"
        }
      })
    }
  }

  onPublishedAtButtonClick(event) {
    if (this.hasPublishedAtValue) {
      this.PublishedAtContainerTarget.classList.toggle("hidden")
      this.PublishedAtPresentMessageTarget.classList.toggle("hidden")
    } else {
      this.PublishedAtContainerTarget.classList.toggle("hidden")
    }
  }

  updateMeetingPublished(published) {
    let meetingData = { meeting: { published: published } }

    if (published) {
      meetingData.meeting.published_at_now = true
    } else {
      meetingData.meeting.published_at = null
    }

    $.ajax({
      url: $(this.form).attr('action'),
      method: 'PUT',
      data: meetingData,
      dataType: "json",
      success: (json) => {
        this.LabelTarget.innerHTML = json.published ? 'Published' : 'Unpublished'

        if (json.published) {
          this.DeleteButtonTarget.classList.add("hidden")
          this.PublishedAtToggleButtonTarget.classList.add("hidden")
          this.PublishedAtContainerTarget.classList.remove("hidden")
          this.PublishedAtPresentMessageTarget.classList.add("hidden")
          this.PublishedAtLabelTarget.innerHTML = "Published at"
          let flatpickr = this.PublishedAtInputTarget._flatpickr
          flatpickr.setDate(json.published_at, false, flatpickr.config.altFormat)
        } else {
          this.DeleteButtonTarget.classList.remove("hidden")
          this.PublishedAtToggleButtonTarget.classList.remove("hidden")
          this.PublishedAtContainerTarget.classList.add("hidden")
          this.PublishedAtPresentMessageTarget.classList.add("hidden")
          this.PublishedAtLabelTarget.innerHTML = "Publish this meeting at"
          this.PublishedAtInputTarget.value = ""
          let flatpickr = this.PublishedAtInputTarget._flatpickr
          flatpickr.clear()
        }
      },
      error: (response) => {
        console.warn(response)
      }
    })
  }

}