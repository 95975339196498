import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets () {
    return ["source", "tooltipText"]
  }

  initialize() {
  }

  copy(e) {
    e.preventDefault()

    this.sourceTarget.select()
    this.sourceTarget.setSelectionRange(0, 99999); /* For mobile devices */
    document.execCommand("copy")
    window.getSelection().removeAllRanges()

    this.tooltipTextTarget.innerHTML = "Copied!"
  }

  hideTooltip(e) {
    this.tooltipTextTarget.innerHTML = "Copy to Clipboard"
  }
}