import { Controller } from "@hotwired/stimulus"
import Sortable from 'sortablejs'

export default class extends Controller {
  initialize() {
    Sortable.create(this.element, {
      animation: 150,
      ghostClass: '!bg-blue-200',
      onUpdate: (evt) => {
        var url = evt.item.dataset.reorderUrl
        var newPosition = evt.newIndex

        $.ajax({
          url: url + '?new_position='+newPosition,
          method: 'PUT',
          success: function(body) {
            // do nothing
          }
        })
      }
    })
  }

}