import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets () {
    return [
      "AmendmentModal",
      "Title",
      "TitleError",
      "Notes",
      "Loader",
      "Form"
    ]
  }

  get minutesController() {
    var elem = document.getElementById(`item-minutes-${this.itemId}`)
    return this.application.getControllerForElementAndIdentifier(elem, "item-minutes")
  }

  get votingController() {
    var elem = document.getElementById(`voting_form_${this.amendmentId ? 'amendment' : 'item'}_${this.itemId}`)
    return this.application.getControllerForElementAndIdentifier(elem, "voting")
  }

  initialize() {
    this.itemId = this.data.get("itemId")
    this.amendmentId = null
    this.votingForm = document.getElementById(`voting_form_amendment_${this.itemId}`)
    this.formMethod = this.data.get("formMethod")
    this.defaultVoteValue = this.data.get("defaultVoteValue")
    this.formUrl = "/admin/amendments"
    this.saveSuccessful = false
    this.isOpen = false
    this.amendments = []

    $(`.item-${this.itemId}-amendment-item`).each((_, elem) => this.amendments.push($(elem).data('amendment')))
  }

  toggleAmendmentModal() {
    this.AmendmentModalTarget.classList.toggle('opacity-0')
    this.AmendmentModalTarget.classList.toggle('pointer-events-none')
    document.querySelector("body").classList.toggle('modal-active')
    this.isOpen = !this.isOpen

    if (!this.isOpen) {
      this.resetData()
    }
  }

  saveAmendment() {
    return new Promise((resolve) => {
      this.saveSuccessful = false
      if (this.hasLoaderTarget) {
        this.LoaderTarget.classList.remove("hidden")
      }

      var votingFormData = { item_id: this.itemId };
      votingFormData.title = this.TitleTarget.value
      votingFormData.notes = this.NotesTarget.value

      if (this.votingForm) {
        var originalVotingFormData = {}
        votingFormData.voter_roles = []
        votingFormData.voter_votes = []
        new FormData(this.votingForm).forEach((value, key) => {originalVotingFormData[key] = value});
        Object.entries(originalVotingFormData).forEach(([key, value]) => {
          var adminUserId = key.match(/(\d+)\[/g)[0].replace("[", "")

          if (key.indexOf("voter_role") >= 0) {
            votingFormData.voter_roles.push({ admin_user_id: adminUserId, selection: value })
          }
          if (key.indexOf("voter_vote") >= 0) {
            votingFormData.voter_votes.push({ admin_user_id: adminUserId, selection: value })
          }
        })
      }

      fetch(this.formUrl, {
        method: this.formMethod,
        credentials: "same-origin",
        headers:  {
          "Content-Type": "application/json",
          "Accept": "application/json"
        },
        body: JSON.stringify({ amendment: votingFormData })
      })
        .then(response => response.json())
        .then(response => {
          setTimeout(() => {
            if (this.hasLoaderTarget) {
              this.LoaderTarget.classList.add("hidden")
            }
          }, 500)
          this.TitleErrorTarget.classList.add("hidden")
          this.TitleTarget.classList.remove("border-red-500")

          if (response.errors) {
            if (response.errors.title !== null) {
              this.TitleErrorTarget.classList.remove("hidden")
              this.TitleTarget.classList.add("border-red-500")
              this.TitleErrorTarget.innerHTML = response.errors.title[0]
              this.saveSuccessful = false
            }
          } else {
            // consumed by saveAmendmentAndClose below
            this.saveSuccessful = true
            this.updateAmendmentsList(response)
            this.updateAutogeneratedText(response)
          }

          resolve()
        })
        .catch(response => {
          console.error(response)
          resolve()
        })
    })
  }

  async saveAmendmentAndClose() {
    await this.saveAmendment()

    if (this.saveSuccessful) {
      this.toggleAmendmentModal()
      this.resetData()
    }
  }

  resetData() {
    this.FormTarget.reset()
    if (this.votingForm) {
      $(this.votingForm).find("[data-votable-type='voter_role']").each((_, elem) => $(elem).val(["voter"]))
      $(this.votingForm).find("[data-votable-type='voter_vote']").each((_, elem) => $(elem).val([this.defaultVoteValue]))
    }
    this.amendmentId = null
    this.formMethod = "POST"
    this.formUrl = "/admin/amendments"
  }

  updateAmendmentsList(response) {
    var minutesList = $(`#item-${this.itemId}-minutes-list`)
    minutesList.html("")

    response.amendments_list.map((amendment) => {
      var elem = $("#amendment-item-placeholder").clone()
      elem.html(amendment.title)
      elem.addClass(`item-${this.itemId}-amendment-item`)
      elem.removeClass("hidden")
      elem.attr('id', '')
      $(elem).attr("data-amendment", JSON.stringify(amendment))
      minutesList.append(elem)
    })

    if (response.amendments_list.length > 0) {
      $(minutesList).removeClass("hidden")
    } else {
      $(minutesList).addClass("hidden")
    }
  }

  updateAutogeneratedText(response) {
    this.minutesController.updateSummaryWithResponse(response)
  }

  openAmendment(e) {
    e.preventDefault()
    this.formMethod = "PUT"
    var amendmentData = $(e.target).data("amendment")
    if (typeof amendmentData == "string") {
      amendmentData = JSON.parse(amendmentData.replace(/\=\>/g, ":"))
    }

    this.amendmentId = amendmentData.id
    this.formUrl += `/${this.amendmentId}`
    this.TitleTarget.value = amendmentData.title
    this.NotesTarget.value = amendmentData.notes
    if (this.votingForm) {
      $(this.votingForm).find("[data-votable-type='voter_role']").attr("checked", false)
      $(this.votingForm).find("[data-votable-type='voter_vote']").attr("checked", false)
      amendmentData.voting_data.voter_roles.map((voter_role) => {
        var input = $(`#amendment_${this.itemId}_voter_role_user_${voter_role.admin_user_id}_selection_${voter_role.selection}`)
        $(input).prop('checked', true)
      })
      amendmentData.voting_data.voter_votes.map((voter_vote) => {
        var input = $(`#amendment_${this.itemId}_voter_vote_user_${voter_vote.admin_user_id}_selection_${voter_vote.selection}`)
        input.prop('checked', true)
      })
    }

    if (this.votingController) {
      this.votingController.updateTotals()
    }
    this.toggleAmendmentModal()
  }

  deleteAmendment(e) {
    var choice = confirm("Are you sure you want to delete this amendment?")

    if (choice == true) {
      fetch(this.formUrl, {
        method: "DELETE",
        credentials: "same-origin",
        headers:  {
          "Content-Type": "application/json",
          "Accept": "application/json"
        }
      })
        .then(response => response.json())
        .then(response => {
          setTimeout(() => {
            if (this.hasLoaderTarget) {
              this.LoaderTarget.classList.add("hidden")
            }
          }, 500)

          this.updateAmendmentsList(response)
          this.updateAutogeneratedText(response)
          this.toggleAmendmentModal()
          this.resetData()
        })
        .catch(console.error)
    }
  }

}