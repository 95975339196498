import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets() {
    return [
    ]
  }

  initialize() {
    this.loaded = false
    this.partialPath = this.data.get("partialPath")
    this.partialName = this.data.get("partialName")
    this.resourceId = this.data.get("resourceId")
    this.resourceType = this.data.get("resourceType")
    this.localsKey = this.data.get("localsKey")
    this.respondToVisibility()
  }

  respondToVisibility() {
    var observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        this.isVisible(entry.intersectionRatio > 0);
      });
    }, {
      root: document.documentElement,
    });

    observer.observe(this.element);
  }

  isVisible(visible) {
    if (visible && this.loaded == false) {
      this.fetchPartialAndReplaceContent()
    }
  }

  fetchPartialAndReplaceContent() {
    var params = {
      partial_name: this.partialName,
      resource_id: this.resourceId,
      resource_type: this.resourceType,
      locals_key: this.localsKey
    }

    fetch(`${this.partialPath}?` + new URLSearchParams(params), {
      credentials: "same-origin"
    })
      .then(response => response.text())
      .then(html => {
        this.element.outerHTML = html
      })
      .catch(console.error)
  }

}