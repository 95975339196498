import { Controller } from "@hotwired/stimulus"
import DirtyForm from 'dirty-form'

export default class extends Controller {
  static get targets () {
    return ["ItemForm", "FilesForm", "FilesFormInput", "SubmitButton"]
  }

  connect() {
    new DirtyForm(this.ItemFormTarget, { 'message': 'You have unsaved changes. Are you sure you want to leave?'})
  }

  onPreFileUpload() {
    this.SubmitButtonTarget.disabled = true
  }

  onPostFileUpload() {
    this.SubmitButtonTarget.disabled = false
  }

  onVicinityMapChange(e) {
    e.preventDefault()

    var files = e.target.files
    var clonedFile
    var dt = new DataTransfer();

    if (files.length > 0) {
      clonedFile = files[0].slice(0, files[0].size, files[0].type);
      dt.items.add(
        new File([clonedFile], files[0].name)
      )
    }

    const form = document.querySelector("#ItemFileDropzoneForm")
    const fileInput = form.querySelector('[data-file-dropzone-target="FileInput"]')
    fileInput.files = dt.files
    const event = new Event('change')
    fileInput.dispatchEvent(event)
    setTimeout(() => form.reset(), 1000)
  }

  onSubmit(e) {
    e.preventDefault()

    this.ItemFormTarget.requestSubmit()
  }

}