import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets () {
    return [
      "ChevronUp",
      "ChevronDown",
      "Content",
      "HeadingContainer"
    ]
  }

  connect() {
    this.element[this.identifier] = this
  }

  initialize() {
    this.sectionLabelId    = this.data.get('sectionLabelId')
    this.localStorageId    = `section-label-minutes-${this.sectionLabelId}-expanded`
    var storageExpanded    = JSON.parse(localStorage.getItem(this.localStorageId))
    this.expanded          = storageExpanded || false

    this.updateExpandedStyle()
  }

  toggleExpanded(e) {
    this.expanded = !this.expanded
    localStorage.setItem(this.localStorageId, this.expanded)
    this.updateExpandedStyle()
  }

  updateExpandedStyle() {
    if (this.expanded == true) {
      this.ContentTarget.style.display = ""
      this.ChevronUpTarget.style.display = ""
      this.ChevronDownTarget.style.display = "none"
      this.HeadingContainerTarget.classList.add("bg-gray-200")
    } else {
      this.ContentTarget.style.display = "none"
      this.ChevronUpTarget.style.display = "none"
      this.ChevronDownTarget.style.display = ""
      this.HeadingContainerTarget.classList.remove("bg-gray-200")
    }
  }

}