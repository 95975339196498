import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets() {
    return ["checkbox", "label", "updatedAt"]
  }

  onInputChange(event) {
    event.preventDefault();

    if (event.currentTarget.checked) {
      this.updateItemPublished(true)
    } else {
      this.updateItemPublished(false)
    }
    this.updatePacketItemsUnpublished()
  }

  labelFor(published) {
    return published ? "Published" : "Unpublished"
  }

  updateItemPublished(published) {
    this.element.dataset.itemPublishPublished = published
    var data = {}
    data[this.data.get('listableType')] = { published: published }

    $.ajax({
      url: this.data.get('publishUrl'),
      method: 'PUT',
      data: data,
      dataType: "json",
      success: (json) => {
        this.checkboxTarget.checked = json.published
        this.labelTarget.innerHTML = this.labelFor(json.published)
        this.updatedAtTarget.innerHTML= `Updated a few seconds ago`
      },
      error: function(response) {
        console.log('response: ', response)
      }
    })
  }

  updatePacketItemsUnpublished() {
    const itemElems = Array.from(document.querySelectorAll("[data-controller='item-publish']"))
    const anyUnpublished = itemElems.some(elem => elem.dataset.itemPublishPublished == "false")
    const packetControllerElem = document.querySelectorAll("[data-controller='packet-attachment']")[0]
    packetControllerElem.dataset.packetAttachmentUnpublishedItems = anyUnpublished
  }

}