import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets () {
    return [
      "ContentContainer",
      "StreamKeyInput",
      "DeleteButton",
    ]
  }

  initialize() {
    this.newStreamUrl = this.data.get("newStreamUrl")
  }

  onCreateStreamClick(e) {
    e.preventDefault()
    e.target.disabled = true

    fetch(this.newStreamUrl, {
      method: "POST",
      credentials: "same-origin",
      headers:  {
        "Content-Type": "application/json",
        "Accept": "application/json"
      }
    })
      .then(response => response.json())
      .then(response => {
        e.target.disabled = false
        this.ContentContainerTarget.classList.remove("hidden")
        this.DeleteButtonTarget.classList.remove("hidden")
        this.StreamKeyInputTarget.value = response.stream_key
      })
      .catch(console.error)
  }

  onDeleteStreamClick(e) {
    e.preventDefault()
    e.target.disabled = true

    fetch(this.newStreamUrl, {
      method: "DELETE",
      credentials: "same-origin",
      headers:  {
        "Content-Type": "application/json",
        "Accept": "application/json"
      }
    })
      .then(response => response.json())
      .then(response => {
        e.target.disabled = false
        this.ContentContainerTarget.classList.add("hidden")
        this.DeleteButtonTarget.classList.add("hidden")
      })
      .catch(console.error)
  }

}