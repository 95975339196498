import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets () {
    return [
      "VideoUrl",
      "VideoUrlError",
      "VideoContainer",
      "VideoIframe",
      "SrcDocContainer"
    ]
  }

  get minutesController() {
    var elem = document.getElementById(`item-minutes-${this.itemId}`)
    return this.application.getControllerForElementAndIdentifier(elem, "item-minutes")
  }

  initialize() {
    this.formUrl = this.data.get('formUrl')
    this.itemId = this.data.get("itemId")
    this.videoUrl = this.VideoUrlTarget.value
    this.youtubeId = this.VideoIframeTarget.dataset.youtubeId
    this.updateVideoStyle()
    this.setIframeSrcAndSrcDoc()
    this.onVideoUrlChange = debounce(this.onVideoUrlChange.bind(this), 250)
  }

  updateVideoStyle() {
    if (this.videoUrl !== "") {
      this.VideoContainerTarget.classList.remove("hidden")
    } else {
      this.VideoContainerTarget.classList.add("hidden")
    }
  }

  setIframeSrcAndSrcDoc() {
    if (this.videoUrl != "") {
      var srcDoc = `<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href="${this.youtubeEmbedUrl(this.videoUrl)}?autoplay=1"><img src="https://img.youtube.com/vi/${this.youtubeId}/0.jpg"><span>&#x25BA;</span></a>`
      this.VideoIframeTarget.srcdoc = srcDoc
      this.VideoIframeTarget.src = this.youtubeEmbedUrl(this.videoUrl)
    }
  }

  onVideoUrlChange(event) {
    fetch(this.formUrl, {
      method: "PUT",
      credentials: "same-origin",
      headers:  {
        "Content-Type": "application/json",
        "Accept": "application/json"
      },
      body: JSON.stringify({ item: { video_url: event.target.value }})
    })
      .then(response => response.json())
      .then(response => {
        if (response.errors) {
          this.VideoUrlErrorTarget.innerHTML = `URL ${response.errors.video_url[0]}`
          this.VideoUrlTarget.classList.add("border-red-600")
          this.VideoContainerTarget.classList.add("hidden")
          this.VideoUrlErrorTarget.classList.remove("hidden")
        } else {
          this.videoUrl = response.video_url
          if (this.videoUrl !== "") {
            this.youtubeId = this.youtubeIdFromUrl(this.videoUrl)
            this.setIframeSrcAndSrcDoc()
          }
          this.VideoUrlTarget.classList.remove("border-red-600")
          this.VideoUrlErrorTarget.classList.add("hidden")
          this.updateVideoStyle()
          this.minutesController.updateSummaryWithResponse(response)
        }
      })
      .catch(console.error)
  }

  youtubeIdFromUrl(yt_url) {
    var urlParams = new URLSearchParams(yt_url.substring(yt_url.indexOf("?")))
    if (urlParams.get("v") !== null) {
      return urlParams.get("v")
    }
  }

  youtubeEmbedUrl(yt_url) {
    var urlParams = new URLSearchParams(yt_url.substring(yt_url.indexOf("?")))
    var result = ""

    if (urlParams.get("v") !== null) {
      // youtu.be ID type videos
      result += urlParams.get("v")
    }

    if (urlParams.get("t") !== null) {
      result += "?start="+urlParams.get("t")
    }

    return "https://www.youtube.com/embed/" + result
  }

}