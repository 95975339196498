import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["ContentSection", "LinkUrlSection"]

  onTypeChange(e) {
    if (e.target.value == "Custom") {
      this.ContentSectionTarget.classList.remove("hidden")
      this.LinkUrlSectionTarget.classList.add("hidden")
    } else {
      this.ContentSectionTarget.classList.add("hidden")
      this.LinkUrlSectionTarget.classList.remove("hidden")
    }
  }
}