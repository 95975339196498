import { Controller } from "@hotwired/stimulus"
import "select2"
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static values = {
    attendeeIds: Array,
    attendeeObjects: Array,
    attendeesPersistUrl: String,
    meetingUrl: String
  }

  static targets = ["SelectInput", "List", "ListEmptyMessage", "ListPlaceholderItem"]

  connect() {
    this.element[this.identifier] = this
  }

  initialize() {
    this.attendeeObjects = this.attendeeObjectsValue
    this.attendeeIds = this.attendeeIdsValue

    $(this.SelectInputTarget).select2({
      multiple: true,
      placeholder: "Select some attendees..."
    })

    $(this.element).on("select2:select", (e) => {
      this.onChange(e)
    })
    $(this.element).on("select2:unselect", (e) => {
      this.onChange(e)
    })
  }

  async onChange(e) {
    e.preventDefault()
    e.stopPropagation()

    const attendeeIds = $(this.SelectInputTarget).select2("data").map((obj) => parseInt(obj.id))

    const request = new FetchRequest('patch', this.attendeesPersistUrlValue, { body: { body: { 'minutes_regular_meeting_attendee_ids': attendeeIds } }, responseKind: 'json' })
    const response = await request.perform()

    if (response.ok) {
      // do nothing
    } else {
      let responseJson = await response.json

      alert(`There was an error saving this data: ${responseJson.errors}`)
    }

    const newItems = attendeeIds.filter(x => !this.attendeeIds.includes(x))
    if (this.attendeeIds.length == 0 && newItems.length > 0) {
      this.ListEmptyMessageTarget.classList.add("hidden")
      this.ListTarget.classList.remove("hidden")
    }
    newItems.map((id) => this.appendAttendee(this.attendeeObjects.find((obj) => parseInt(obj.id) === id)))
    const removedItems = this.attendeeIds.filter(x => !attendeeIds.includes(x))
    if (this.attendeeIds.length > 0 && newItems.length == 0 && removedItems.length > 0) {
      this.ListEmptyMessageTarget.classList.remove("hidden")
      this.ListTarget.classList.add("hidden")
    }
    removedItems.map((id) => this.removeAttendee(this.attendeeObjects.find((obj) => parseInt(obj.id) === id)))

    this.attendeeIds = attendeeIds
  }

  appendAttendee(obj) {
    const elem = this.ListPlaceholderItemTarget.cloneNode(true)
    elem.classList.remove("hidden")
    elem.dataset.itemAttendeeId= obj.id
    const nameElem = elem.querySelectorAll("[data-target='ItemName']")[0]
    nameElem.innerHTML = obj.name
    const presentRadio = elem.querySelectorAll("[data-target='PresentRadio']")[0]
    presentRadio.setAttribute("id", `roll_call_attendee_id_${obj.id}_present`)
    presentRadio.setAttribute("name", `roll_call[attendee_id][${obj.id}]`)
    const presentLabel = elem.querySelectorAll("[data-target='PresentLabel']")[0]
    presentLabel.setAttribute("for", `roll_call_attendee_id_${obj.id}_present`)
    const absentRadio = elem.querySelectorAll("[data-target='AbsentRadio']")[0]
    absentRadio.setAttribute("id", `roll_call_attendee_id_${obj.id}_absent`)
    absentRadio.setAttribute("name", `roll_call[attendee_id][${obj.id}]`)
    const absentLabel = elem.querySelectorAll("[data-target='AbsentLabel']")[0]
    absentLabel.setAttribute("for", `roll_call_attendee_id_${obj.id}_absent`)
    this.ListTarget.append(elem)
  }

  removeAttendee(obj) {
    const listItem = this.ListTarget.querySelectorAll(`[data-item-attendee-id='${obj.id}']`)[0]
    listItem.remove()
  }

  async onPresenceChange(e) {
    let selectionValue = {}
    Array.from(this.ListTarget.children).map((child) => {
      if (child.dataset.itemAttendeeId !== undefined) {
        const presentInput = child.querySelectorAll("input[value='present']")[0]
        if (presentInput.checked) {
          selectionValue[child.dataset.itemAttendeeId] = 'present'
        }
        const absentInput = child.querySelectorAll("input[value='absent']")[0]
        if (absentInput.checked) {
          selectionValue[child.dataset.itemAttendeeId] = 'absent'
        }
      }
    })

    const request = new FetchRequest('patch', this.meetingUrlValue, { body: { meeting: { 'roll_call_attendee_selection': selectionValue } }, responseKind: 'json' })
    const response = await request.perform()

    if (response.ok) {
      // do nothing
    } else {
      let responseJson = await response.json

      alert(`There was an error saving this data: ${responseJson.errors}`)
    }
  }

}