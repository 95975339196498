import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

export default class extends Controller {
  static targets = ["ProjectCommentMailerCCListInput"]

  connect() {
    new TomSelect(this.ProjectCommentMailerCCListInputTarget)
  }

}