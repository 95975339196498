import { Controller } from "@hotwired/stimulus"
import {enter, leave} from 'el-transition'
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static targets = [
    "NewModalContainer", "NewModalBg", "NewModalPanel",
    "EditModalContainer", "EditModalBg", "EditModalPanel",
    "Form", "TypeInput", "DynamicFields",
  ]

  onNewClick(e) {
    this.NewModalContainerTarget.classList.remove("hidden")
    enter(this.NewModalBgTarget)
    enter(this.NewModalPanelTarget)
  }

  onCloseNewClick(e) {
    Promise.all([
      leave(this.NewModalBgTarget),
      leave(this.NewModalPanelTarget),
    ]).then(() => {
      this.NewModalContainerTarget.classList.add("hidden")
    })
  }

  onCloseEditClick(e) {
    Promise.all([
      leave(this.EditModalBgTarget),
      leave(this.EditModalPanelTarget),
    ]).then(() => {
      this.EditModalContainerTarget.classList.add("hidden")
    })
  }

  onTypeChange(e) {
    this.DynamicFieldsTargets.map((dynamicFields) => {
      if (dynamicFields.dataset.type == e.currentTarget.value) {
        dynamicFields.classList.remove("hidden")
      } else {
        dynamicFields.classList.add("hidden")
      }
    })
  }

  onSaveAndPublish(e) {
    this.handleFormSubmit("published", e.target.dataset.timelineItemId)
  }

  onSaveAsDraft(e) {
    this.handleFormSubmit("draft", e.target.dataset.timelineItemId)
  }

  async onEditClick(e) {
    const request = new FetchRequest("GET", e.currentTarget.dataset.editUrl, { responseKind: 'turbo-stream' })
    const response = await request.perform()

    if (response.ok) {
      const elem = document.getElementById(`dropdown-controller-${e.target.dataset.timelineItemId}`)
      const controller = this.application.getControllerForElementAndIdentifier(elem, "dropdown")
      controller.toggleMenu()
    } else {
      let responseJson = await response.json

      console.warn('responseJson', responseJson)
    }
  }

  async handleFormSubmit(status = "published", timelineItemId) {
    let form

    if (timelineItemId) {
      form = document.querySelector(`form[data-timeline-item-id='${timelineItemId}']`)
    } else {
      form = document.querySelector(`form[data-project-timeline-target='Form']`)
    }

    const formData = new FormData(form)
    formData.append('timeline_item[status]', status)

    const request = new FetchRequest(form.method, form.action, { body: formData, responseKind: 'turbo-stream' })
    const response = await request.perform()

    if (response.ok) {
      // do nothing
    } else {
      let responseJson = await response.json

      console.warn('responseJson', responseJson)
    }
  }

  async onStatusChangeClick(e) {
    const request = new FetchRequest("GET", e.target.dataset.url, { responseKind: 'turbo-stream' })
    const response = await request.perform()

    if (response.ok) {
      const elem = document.getElementById(`dropdown-controller-${e.target.dataset.timelineItemId}`)
      const controller = this.application.getControllerForElementAndIdentifier(elem, "dropdown")
      controller.toggleMenu()
    } else {
      let responseJson = await response.json

      console.warn('responseJson', responseJson)
    }
  }

  async onDeleteClick(e) {
    if (window.confirm("Are you sure you want to delete this Timeline Item?")) {
      const request = new FetchRequest("DELETE", e.target.dataset.url, { responseKind: 'turbo-stream' })
      const response = await request.perform()

      if (response.ok) {
        const elem = document.getElementById(`dropdown-controller-${e.target.dataset.timelineItemId}`)
        const controller = this.application.getControllerForElementAndIdentifier(elem, "dropdown")
        controller.toggleMenu()
      } else {
        let responseJson = await response.json

        console.log('responseJson', responseJson)
      }
    } else {
      const elem = document.getElementById(`dropdown-controller-${e.target.dataset.timelineItemId}`)
      const controller = this.application.getControllerForElementAndIdentifier(elem, "dropdown")
      controller.toggleMenu()
    }
  }

}