import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["Form", "FileInput", "UploadingContainer", "FilenamesList", "ProgressBar", "ErrorContainer", "ErrorText"]
  static values = {
    preUploadAction: String,
    postUploadAction: String,
    appendListItemAction: String,
    appendListItemId: String,
    originDomain: String
  }

  connect() {
    this.conversionIntervals = {}
    this.allFilenames = []
    this.droppedFiles = false
    const div = document.createElement('div');
    this.isAdvancedUpload = (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window

    this.initializeEventListeners()
  }

  initializeEventListeners() {
    // submit form on file input change (drop)
    this.FileInputTarget.addEventListener('change', (e) => {
      this.onSubmit(e)
    })

    if (this.isAdvancedUpload) {
      window.addEventListener('dragover', this.stopPropagation)
      window.addEventListener('drop', this.stopPropagation)

      this.FormTarget.classList.add("has-advanced-upload")

      this.FormTarget.addEventListener('drag', this.stopPropagation)
      this.FormTarget.addEventListener('dragstart', this.stopPropagation)
      this.FormTarget.addEventListener('dragover', (e) => {
        this.stopPropagation(e)
        this.FormTarget.classList.add('is-dragover')
      })
      this.FormTarget.addEventListener('dragenter', (e) => {
        this.stopPropagation(e)
        this.FormTarget.classList.add('is-dragover')
      })
      this.FormTarget.addEventListener('dragleave', (e) => {
        this.stopPropagation(e)
        this.FormTarget.classList.remove('is-dragover')
      })
      this.FormTarget.addEventListener('dragend', (e) => {
        this.stopPropagation(e)
        this.FormTarget.classList.remove('is-dragover')
      })
      this.FormTarget.addEventListener('drop', (e) => {
        this.stopPropagation(e)
        this.FormTarget.classList.remove('is-dragover')
        this.droppedFiles = e.dataTransfer.files
        this.onSubmit(e)
      })
    }

    this.FormTarget.addEventListener('submit', this.onSubmit)
  }

  stopPropagation(e) {
    e.preventDefault()
    e.stopPropagation()
  }

  callControllerMethod(controllerAndMethod, payload = null, formElement = null, id = null) {
    const parts = controllerAndMethod.split('->')
    const controller = parts[0]
    const method = parts[1]
    let controllerElement
    if (id) {
      const controllerElems = document.querySelectorAll(`[data-controller="${controller}"]`)
      controllerElement = Array.from(controllerElems).find((elem) => elem.id == id)
    } else {
      controllerElement = document.querySelectorAll(`[data-controller="${controller}"]`)[0]
    }
    const controllerInstance = this.application.getControllerForElementAndIdentifier(controllerElement, controller)
    if (controllerInstance) {
      if (payload && formElement) {
        controllerInstance[method](payload, formElement)
      } else if (payload) {
        controllerInstance[method](payload)
      } else if (formElement) {
        controllerInstance[method](formElement)
      } else {
        controllerInstance[method]()
      }
    }
  }

  onSubmit(e) {
    e.preventDefault()

    if (this.FormTarget.classList.contains('is-uploading')) return false;

    this.FormTarget.classList.add('is-uploading')
    this.FormTarget.classList.remove('is-error')

    if (this.isAdvancedUpload) {
      this.uploadAdvanced(e) // ajax for modern browsers
    } else {
      this.uploadLegacy(e) // ajax for legacy browsers
    }
  }

  async uploadAdvanced(e) {
    e.preventDefault()

    let formData = new FormData(this.FormTarget)

    if (this.droppedFiles) {
      Array.from(this.droppedFiles).map((file) => {
        formData.append(this.FileInputTarget.getAttribute('name'), file)
        this.allFilenames.push(file.name)
      })
    }

    if (this.preUploadActionValue != "") {
      this.callControllerMethod(this.preUploadActionValue, formData)
    }

    const xhr = new XMLHttpRequest()
    xhr.open(this.FormTarget.getAttribute('method'), this.FormTarget.getAttribute('action'))
    xhr.withCredentials = true
    xhr.setRequestHeader('Access-Control-Allow-Origin', this.originDomainValue)
    xhr.setRequestHeader('Access-Control-Allow-Methods', 'HEAD, GET, POST, PUT, PATCH, DELETE')
    xhr.setRequestHeader('Access-Control-Allow-Headers', 'Accept, Content-Type, Referer, Origin, User-Agent')
    xhr.setRequestHeader('Access-Control-Allow-Credentials', true)

    // upload progress event
    xhr.upload.addEventListener('progress', this.onProgress.bind(this))

    // request finished event
    xhr.addEventListener('load', (e) => {
      if (xhr.status < 400) {
        const data = JSON.parse(xhr.response)

        this.FormTarget.classList.add(data.success == true ? 'is-success' : 'is-error')

        if (this.appendListItemActionValue != "") {
          data.attachments.map((attachment) => {
            if (this.appendListItemIdValue != "") {
              this.callControllerMethod(this.appendListItemActionValue, attachment, null, this.appendListItemIdValue)
            } else {
              this.callControllerMethod(this.appendListItemActionValue, attachment, null)
            }
          })
        }

        if (this.postUploadActionValue != "") {
          this.callControllerMethod(this.postUploadActionValue, data, this.FormTarget)
        }

        this.FormTarget.classList.remove('is-uploading')
        this.allFilenames = []
      } else {
        // log the error, show alert, etc.
        if (xhr.status == 400) {
          const data = JSON.parse(xhr.response)
          this.ErrorTextTarget.innerHTML = data.errors.join(" & ")
        } else {
          this.ErrorTextTarget.innerHTML = "File uploading failed. Please try again and <a href='mailto:support@peoplespeak.net'>contact us</a> if this problem persists."
        }

        this.ErrorContainerTarget.classList.remove("hidden")

        if (window.Sentry) {
          Sentry.captureException('Error Uploading Attachment file: ' + e.statusText)
        }

        if (this.postUploadActionValue != "") {
          this.callControllerMethod(this.postUploadActionValue)
        }

        this.FormTarget.classList.remove('is-uploading')
        this.allFilenames = []
      }
    })

    xhr.send(formData)
  }

  uploadLegacy() {
    const iframeName = `uploadiframe${new Date().getTime()}`
    const iframeElem = document.createElement("iframe")
    iframeElem.setAttribute('name', iframeName)
    iframeElem.style = "display: none;"

    document.body.appendElement(iframeElem)
    this.FormTarget.setAttribute('target', iframeName);

    iframeElem.addEventListener('load', () => {
      const data = JSON.parse(iframeElem.contents().find('body').text())
      this.FormTarget.classList.remove('is-uploading')
      this.FormTarget.classList.add(data.success == true ? 'is-success' : 'is-error')
      this.FormTarget.removeAttribute('target')
      if (!data.success) this.ErrorContainerTarget.innerHTML = `Oops! ${data.error}`
      iframeElem.remove()
    })
  }

  onProgress(e) {
    if (e.lengthComputable) {
      const max = e.total;
      const current = e.loaded;
      const percentage = (current * 100) / max;
      this.showFilenames(this.allFilenames)

      if (percentage >= 100) {
        // process completed
        this.updateProgressBar(percentage)
      } else {
        // in progress
        this.updateProgressBar(percentage)
      }
    }
  }

  updateProgressBar(percentage) {
    this.ProgressBarTarget.style.width = `${percentage}%`
  }

  showFilenames(filenames) {
    this.FilenamesListTarget.innerHTML = filenames.join(", ")
    this.UploadingContainerTarget.classList.remove("hidden")
  }

  onConversionError() {
    this.ErrorTextTarget.innerHTML = "Something went wrong. Please try refreshing the page and <a href='mailto:support@peoplespeak.net'>contact us</a> if this problem persists."
    this.ErrorContainerTarget.classList.remove("hidden")
    this.element.classList.add('is-error')
  }

}