import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  onSelectAllClick(e) {
    e.preventDefault()

    const checkboxes = this.element.querySelectorAll('input[type="checkbox"]')

    checkboxes.forEach((checkbox) => {
      checkbox.checked = true
    })
  }

  onDeselectAllClick(e) {
    e.preventDefault()

    const checkboxes = this.element.querySelectorAll('input[type="checkbox"]')

    checkboxes.forEach((checkbox) => {
      checkbox.checked = false
    })
  }

  onDebugClick(e) {
    history.pushState({}, '', e.target.href)
  }

  onImportClick(e) {
    e.target.disabled = true
    e.target.innerText = "Importing..."
    // re-enable any disabled Item checkboxes
    const itemsList = document.querySelectorAll(`input[name="meeting[items_list][]"]`)
    itemsList.forEach((item) => { item.disabled = false })

    e.target.form.requestSubmit()
  }

  onParentItemCheckboxChange(e) {
    const parentCheckbox = e.target;
    const isChecked = parentCheckbox.checked;
    const itemId = parentCheckbox.dataset.itemId

    if (!isChecked) {
      // If unchecked, uncheck all nested checkboxes
      const nestedHiddenInputsMatchingItemID = document.querySelectorAll(`input[name="meeting[itemfilenames_list][]"][data-item-id="${itemId}"]`);
      const nestedCheckboxes = []
      nestedHiddenInputsMatchingItemID.forEach((elem) => nestedCheckboxes.push(elem.parentNode.querySelector('input[type="checkbox"]')))
      nestedCheckboxes.forEach((checkbox) => {
        checkbox.checked = false;
      })
    } else if (isChecked) {
      // If checked, check all nested checkboxes
      const nestedHiddenInputsMatchingItemID = document.querySelectorAll(`input[name="meeting[itemfilenames_list][]"][data-item-id="${itemId}"]`);
      const nestedCheckboxes = []
      nestedHiddenInputsMatchingItemID.forEach((elem) => nestedCheckboxes.push(elem.parentNode.querySelector('input[type="checkbox"]')))
      nestedCheckboxes.forEach((checkbox) => {
        checkbox.checked = true;
      })
    }
  }

  onItemFileCheckboxChange(e) {
    const fileCheckbox = e.target;
    const isChecked = fileCheckbox.checked;
    const itemId = fileCheckbox.dataset.itemId
    const parentItemCheckbox = document.querySelector(`#meeting_items_list_${itemId}`)

    if (isChecked) {
      parentItemCheckbox.checked = true;
      parentItemCheckbox.disabled = true;
    } else {
      // if all other sibling checkboxes are unchecked, enable the parent checkbox
      const nestedHiddenInputsMatchingItemID = document.querySelectorAll(`input[name="meeting[itemfilenames_list][]"][data-item-id="${itemId}"]`);
      const nestedCheckboxes = []
      nestedHiddenInputsMatchingItemID.forEach((elem) => nestedCheckboxes.push(elem.parentNode.querySelector('input[type="checkbox"]')))
      if (nestedCheckboxes.every((checkbox) => checkbox.checked == false)) {
        parentItemCheckbox.disabled = false;
      }
    }
  }

}