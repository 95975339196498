import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets() {
    return [
      "Loader",
      "Container",
      "SelectedSessionContainer",
      "SelectedSessionName",
      "PortalLink",
      "VideoUrlLink",
      "PsVideoUrlLink",
      "VideoUrlSyncedAt",
      "CuepointsList",
      "CuepointsSyncedAt",
      "SelectFromSessionsContainer",
      "SessionsSelectInput",
      "YearInput",
    ]
  }

  initialize() {
    this.sessionId = this.data.get("sessionId")
    this.storeSessionIdPath = this.data.get("storeSessionIdPath")
    this.syncPath = this.data.get("syncPath")
    this.sessionsProxyPath = this.data.get("sessionsProxyPath")
    this.sessionProxyPath = this.data.get("sessionProxyPath")
    this.sessionCuepointsProxyPath = this.data.get("sessionCuepointsProxyPath")
    this.session = {}
    this.sessionsList = []

    $(this.LoaderTarget).find(".loading-spinner-container").removeClass("hidden")

    if (this.sessionId !== "") {
      this.fetchSessionData()
    } else {
      this.fetchSessionsListData()
    }
  }

  showContainer() {
    $(this.LoaderTarget).find(".loading-spinner-container").addClass("hidden")
    this.LoaderTarget.classList.add("hidden")
    this.ContainerTarget.classList.remove("hidden")
  }

  showLoader() {
    $(this.LoaderTarget).find(".loading-spinner-container").removeClass("hidden")
    this.LoaderTarget.classList.remove("hidden")
    this.ContainerTarget.classList.add("hidden")
  }

  showSelectedSessionContainer() {
    this.SelectedSessionContainerTarget.classList.remove("hidden")
  }

  showSessionsList() {
    this.SelectFromSessionsContainerTarget.classList.remove("hidden")
  }

  hideSelectedSession() {
    this.SelectedSessionContainerTarget.classList.add("hidden")
  }

  hideSessionsList() {
    this.SelectFromSessionsContainerTarget.classList.add("hidden")
  }

  setUiSessionTitle() {
    if (this.session.title) {
      this.SelectedSessionNameTarget.innerHTML = this.session.title
      this.PortalLinkTarget.href = this.session.url
    }
  }

  setUiSessionVideoUrl() {
    this.VideoUrlLinkTarget.innerHTML = this.session.video_url || ""
    this.VideoUrlLinkTarget.href = this.session.video_url || ""
  }

  setUiSessionPsVideoUrl() {
    this.PsVideoUrlLinkTarget.innerHTML = this.session.video_url || ""
    this.PsVideoUrlLinkTarget.href = this.session.video_url || ""
    this.VideoUrlSyncedAtTarget.innerHTML = strftime("%B %d, %Y, %l:%M %P", new Date())
  }

  setUiCuepointsList(cuepointsList) {
    $(this.CuepointsListTarget).find('li').remove()

    cuepointsList.map((cuepoint) => {
      var li = document.createElement('li')
      li.classList.add("cuepoint-list-item", "mt-2")
      var itemLink = document.createElement('a')
      itemLink.innerHTML = cuepoint.title
      itemLink.href = cuepoint.url
      itemLink.setAttribute('target', '_blank')
      li.appendChild(itemLink)
      var videoElem = document.createElement('span')
      videoElem.classList.add("cuepoint-item-video-url", "ml-2")
      videoElem.innerHTML = "&mdash;"
      var videoLink = document.createElement('a')
      videoLink.innerHTML = cuepoint.video_url
      videoLink.href = cuepoint.video_url
      videoLink.setAttribute('target', '_blank')
      videoElem.appendChild(videoLink)
      li.appendChild(videoElem)
      this.CuepointsListTarget.appendChild(li)
    })
  }

  setUiCuepointsUpdatedAt() {
    this.CuepointsSyncedAtTarget.innerHTML = strftime("%B %d, %Y, %l:%M %P", new Date())
  }

  setUiSessionsSelectInput() {
    Array.from(this.SessionsSelectInputTarget.options).map((_, index) => {
      this.SessionsSelectInputTarget.options[0] = null
    })

    this.sessionsList.map((session) => {
      var option = document.createElement("option")
      option.value = session.id
      option.text = session.title
      this.SessionsSelectInputTarget.add(option)
    })
  }

  fetchSessionData() {
    fetch(this.sessionProxyPath, {
      method: "GET",
      credentials: "same-origin",
      headers:  {
        "Content-Type": "application/json",
        "Accept": "application/json"
      }
    })
      .then(response => response.json())
      .then(response => {
        this.session = response
        this.setUiSessionTitle()
        this.setUiSessionVideoUrl()
        this.showContainer()
      })
      .catch(console.error)
  }

  fetchSessionsListData() {
    this.showLoader()
    this.hideSelectedSession()

    fetch(this.sessionsProxyPath, {
      method: "GET",
      credentials: "same-origin",
      headers:  {
        "Content-Type": "application/json",
        "Accept": "application/json"
      }
    })
      .then(response => response.json())
      .then(response => {
        this.sessionsList = response.results
        this.setUiSessionsSelectInput()
        this.showSessionsList()
        this.showContainer()
      })
      .catch(console.error)
  }

  onLoadByYear() {
    var year = $(this.YearInputTarget).val()

    this.showLoader()
    this.hideSelectedSession()

    fetch(`${this.sessionsProxyPath}?year=${year}`, {
      method: "GET",
      credentials: "same-origin",
      headers:  {
        "Content-Type": "application/json",
        "Accept": "application/json"
      }
    })
      .then(response => response.json())
      .then(response => {
        this.sessionsList = response.results
        this.setUiSessionsSelectInput()
        this.showSessionsList()
        this.showContainer()
      })
      .catch(console.error)
  }

  onSelectSession() {
    var selectedSessionId = $(this.SessionsSelectInputTarget).val()
    var session = this.sessionsList.find((s) => s.id === selectedSessionId)

    this.session = session
    this.setUiSessionTitle()
    this.setUiSessionVideoUrl()
    this.storeSessionIdOnMeeting()
    this.hideSessionsList()
    this.showSelectedSessionContainer()
    this.showContainer()
  }

  onClearSelectedSession() {
    this.session = { id: null, title: null }
    this.SelectedSessionNameTarget.innerHTML = ""
    this.PortalLinkTarget.href = "javascript:void(0)"
    this.storeSessionIdOnMeeting()
    this.fetchSessionsListData()
  }

  onCancelSessionSelect() {
    this.hideSessionsList()
    this.showSelectedSessionContainer()
    this.showContainer()
  }

  storeSessionIdOnMeeting() {
    fetch(this.storeSessionIdPath, {
      method: "PUT",
      credentials: "same-origin",
      headers:  {
        "Content-Type": "application/json",
        "Accept": "application/json"
      },
      body: JSON.stringify({ meeting: { open_media_session_id: this.session.id }})
    })
      .then(response => response.json())
      .then(response => {
      })
      .catch(console.error)
  }

  onSyncVideoUrls() {
    fetch(`${this.syncPath}?session_id=${this.sessionId}&attr=video_url`, {
      method: "GET",
      credentials: "same-origin",
      headers:  {
        "Content-Type": "application/json",
        "Accept": "application/json"
      }
    })
      .then(response => response.json())
      .then(response => {
        this.session = response
        this.setUiSessionTitle()
        this.setUiSessionVideoUrl()
        this.setUiSessionPsVideoUrl()
      })
      .catch(console.error)
  }

  onSyncCuepoints() {
    fetch(`${this.syncPath}?session_id=${this.sessionId}&attr=cuepoints`, {
      method: "GET",
      credentials: "same-origin",
      headers:  {
        "Content-Type": "application/json",
        "Accept": "application/json"
      }
    })
      .then(response => response.json())
      .then(response => {
        this.setUiCuepointsList(response)
        this.setUiCuepointsUpdatedAt()
      })
      .catch(console.error)
  }

}