import { Controller } from "@hotwired/stimulus"
import Sortable from 'sortablejs'

export default class extends Controller {
  static get targets() {
    return [
      "InviteReviewersModal",
      "FormInviteReviewers",
      "CurrentVersionReviewersList",
      "NewReviewerListItem",
      "InputMessage",
      "ErrorMessage",
      "EventsList",
      "VersionsList",
      "NewMessageButton",
      "ApprovalEventForm", "ApprovalEventApprovalIdInput",
      "ApprovalVersionForm", "ApprovalVersionApprovalIdInput", "ApprovalVersionSubmitButton"
    ]
  }

  connect() {
    this.approvalId = this.data.get("approvalId")

    const fileLists = document.querySelectorAll('[data-controller="file-list"]')

    Array.from(fileLists).map((fileList) => {
      Sortable.create(fileList, {
        animation: 150,
        ghostClass: '!bg-blue-200',
        onUpdate: (evt) => {
          var url = evt.item.dataset.reorderUrl
          var attachmentId = evt.item.dataset.id
          var newPosition = evt.newIndex

          if ($("#admin-list-item-placeholder").index() == 0) {
            newPosition -= 1
          }

          $.ajax({
            url: url + '?attachment_id='+attachmentId+'&new_position='+newPosition,
            method: 'PUT',
            success: function(body) {
              // do nothing
            }
          })
        }
      })
    })

    this.debouncedMessageChange = debounce(this.onMessageChange.bind(this), 250)
  }

  onInviteClick() {
    this.InviteReviewersModalTarget.classList.remove("hidden")
  }

  onCloseInviteReviewersClick() {
    this.InviteReviewersModalTarget.classList.add("hidden")
  }

  onInviteReviewersSubmit(e) {
    e.preventDefault()
    e.target.disabled = true

    const data = new URLSearchParams(new FormData(this.FormInviteReviewersTarget));

    fetch(this.FormInviteReviewersTarget.action, {
      method: "PATCH",
      credentials: "same-origin",
      body: data
    })
      .then(response => response.json())
      .then(response => {
        if (response.html) {
          this.EventsListTarget.innerHTML = response.html
        }
        this.replaceVersionsList()
        this.FormInviteReviewersTarget.reset()
        this.onCloseInviteReviewersClick()
        e.target.disabled = false
      })
      .catch(error => alert(error))
  }

  onMessageChange(e) {
    this.InputMessageTarget.classList.remove("border-red-500")
    this.ErrorMessageTarget.classList.add("hidden")

    fetch("/admin/approval_events/all/draft_message", {
      method: "PUT",
      credentials: "same-origin",
      headers:  {
        "Content-Type": "application/json",
        "Accept": "application/json",
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      body: JSON.stringify({ 'approval_event': { message: e.target.value }})
    })
      .catch(console.error)
  }

  onApprovalEventSubmit(e) {
    e.preventDefault()
    this.NewMessageButtonTarget.disabled = true

    this.InputMessageTarget.classList.remove("border-red-500")
    this.ErrorMessageTarget.classList.add("hidden")

    let method = ""
    if (this.ApprovalEventFormTarget.action.endsWith("/approval_events")) {
      method = "POST"
    } else {
      method = "PATCH"
    }

    let attachmentIds = []
    const fileListContainer = document.querySelector("#ApprovalEventFileList")
    const approvalEventFiles = (fileListContainer && Array.from(fileListContainer.querySelectorAll('li[data-attachment-id]'))) || []
    approvalEventFiles.map((item) => {
      attachmentIds.push(item.dataset.attachmentId)
    })
    var formData = { approval_event: {
      message: this.InputMessageTarget.value,
      attachment_ids: attachmentIds,
      approval_id: this.ApprovalEventApprovalIdInputTarget.value
    }}

    fetch(this.ApprovalEventFormTarget.action, {
      method: method,
      credentials: "same-origin",
      headers:  {
        "Content-Type": "application/json",
        "Accept": "application/json",
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      body: JSON.stringify(formData)
    })
      .then(response => response.json())
      .then(response => {
        this.NewMessageButtonTarget.disabled = false

        if (response.errors && response.errors.message) {
          this.InputMessageTarget.classList.add("border-red-500")
          this.ErrorMessageTarget.classList.remove("hidden")
        }

        if (response.html) {
          this.EventsListTarget.innerHTML = response.html
          this.ApprovalEventFormTarget.reset()
          // reset file list
          approvalEventFiles.map((fileElem) => fileElem.remove())
          this.InputMessageTarget.value = ""
          document.querySelector("#ApprovalEventFileDropzoneForm").action = "/admin/approval_event_attachments"
          this.ApprovalEventFormTarget.action = "/admin/approval_events"
          const appendedFormMethodElem = this.ApprovalEventFormTarget.querySelector("input[name='_method']")
          if (appendedFormMethodElem) {
            appendedFormMethodElem.remove()
          }
          this.ApprovalEventFormTarget.setAttribute("id", 'new_approval_event')
          this.ApprovalEventFormTarget.classList.add('new_approval_event')
        }
      })
      .catch(console.error)
  }

  onNewVersionSubmit(e) {
    e.preventDefault()
    e.target.disabled = true

    var method = "PATCH"

    let attachmentIds = []
    const fileListContainer = document.querySelector("#ApprovalVersionFileList")
    const approvalVersionFiles = (fileListContainer && Array.from(fileListContainer.querySelectorAll('li[data-attachment-id]'))) || []
    approvalVersionFiles.map((item) => {
      attachmentIds.push(item.dataset.attachmentId)
    })
    var formData = { approval_version: {
      attachment_ids: attachmentIds,
      approval_id: this.ApprovalVersionApprovalIdInputTarget.value
    }}

    fetch(this.ApprovalVersionFormTarget.action, {
      method: method,
      credentials: "same-origin",
      headers:  {
        "Content-Type": "application/json",
        "Accept": "application/json",
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      body: JSON.stringify(formData)
    })
      .then(response => response.json())
      .then(response => {
        if (response.html) {
          e.target.disabled = false
          this.EventsListTarget.innerHTML = response.html
          this.ApprovalVersionFormTarget.reset()
          // reset file list
          approvalVersionFiles.map((fileElem) => fileElem.remove())
          // update file dropzone form to new version id
          document.querySelector("#ApprovalEventFileDropzoneForm").action = `/admin/approval_versions/${response.new_version_id}/attachments`
          // update new version form to draft version
          this.ApprovalVersionFormTarget.action = `/admin/approval_versions/${response.new_version_id}`
          this.replaceVersionsList()
        }
      })
      .catch(console.error)
  }

  replaceVersionsList() {
    fetch(`/admin/approvals/${this.approvalId}/versions_list`, {
      method: "GET",
      credentials: "same-origin",
      headers:  {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }
    })
      .then(response => response.json())
      .then(response => {
        if (response.html) {
          this.VersionsListTarget.innerHTML = response.html
        }
      })
      .catch(console.error)
  }

  onUnapproveClick(e) {
    e.preventDefault()
    e.target.disabled = true

    fetch(`/admin/approvals/${this.approvalId}/unapprove`, {
      method: "PATCH",
      credentials: "same-origin",
      headers:  {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }
    })
      .then(response => response.json())
      .then(response => {
        if (response.html) {
          e.target.disabled = false
          this.EventsListTarget.innerHTML = response.html
          this.replaceVersionsList()
        }
      })
      .catch(console.error)
  }

  onApproveClick(e) {
    e.preventDefault()
    e.target.disabled = true

    fetch(`/admin/approvals/${this.approvalId}/approve`, {
      method: "PATCH",
      credentials: "same-origin",
      headers:  {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }
    })
      .then(response => response.json())
      .then(response => {
        if (response.html) {
          e.target.disabled = false
          this.EventsListTarget.innerHTML = response.html
          this.replaceVersionsList()
        }
      })
      .catch(console.error)
  }

  onReviewerMouseOver(e) {
    $(e.target).find(".delete-reviewer-button").removeClass("hidden")
  }

  onReviewerMouseOut(e) {
    $(e.target).find(".delete-reviewer-button").addClass("hidden")
  }

  onReviewerDeleteClick(e) {
    const reviewerName = e.target.dataset.reviewerName

    if (window.confirm(`Are you sure you want to delete reviewer: ${reviewerName}?`)) {
      const reviewerId = e.target.dataset.reviewerId
      const deletePath = e.target.dataset.deletePath
      fetch(deletePath, {
        method: "DELETE",
        credentials: "same-origin",
        headers:  {
          "Content-Type": "application/json",
          "Accept": "application/json",
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        },
        body: JSON.stringify({ approval_version: { reviewer: reviewerId } }),
      })
        .then(response => response.json())
        .then(response => {
          if (response.html) {
            this.EventsListTarget.innerHTML = response.html
            this.replaceVersionsList()
          }
        })
        .catch(console.error)
    }
  }

  onVersionMouseOver(e) {
    e.target.querySelector(".delete-version-button").classList.remove("hidden")
  }

  onVersionMouseOut(e) {
    e.target.querySelector(".delete-version-button").classList.add("hidden")
  }

  onVersionDeleteClick(e) {
    const versionNumber = e.target.dataset.versionNumber

    if (window.confirm("Are you sure you want to delete version "+versionNumber+"?")) {
      const deletePath = e.target.dataset.deletePath
      fetch(deletePath, {
        method: "DELETE",
        credentials: "same-origin",
        headers:  {
          "Content-Type": "application/json",
          "Accept": "application/json",
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        },
      })
        .then(response => response.json())
        .then(response => {
          if (response.html) {
            this.EventsListTarget.innerHTML = response.html
            this.replaceVersionsList()
          }
        })
        .catch(console.error)
    }
  }

  onPreFileUpload(formData) {
    formData.append('approval_id', this.ApprovalEventApprovalIdInputTarget.value || this.ApprovalVersionApprovalIdInputTarget.value)
  }

  onPostFileUpload(data, form) {
    const firstAttachment = data.attachments[0]

    if (firstAttachment.resource_type == 'ApprovalEvent') {
      if (this.ApprovalEventFormTarget.action.endsWith("/admin/approval_events")) {
        // set new approval event form to submit to edit version
        this.ApprovalEventFormTarget.action = `/admin/approval_events/${firstAttachment.approval_event_id}`
        this.ApprovalEventFormTarget.insertAdjacentHTML( 'afterbegin', "<input type='hidden' name='_method' value='patch'>")
        // set file dropzone form to submit to version attachments path
        form.action = `/admin/approval_events/${firstAttachment.approval_event_id}/attachments`
      } else {
        if (this.ApprovalEventFormTarget.action.startsWith("/admin/approval_events/")) {
          // set new approval event form to submit to edit version
          this.ApprovalEventFormTarget.action = `/admin/approval_events/${firstAttachment.approval_event_id}`
          this.ApprovalEventFormTarget.insertAdjacentHTML( 'afterbegin', "<input type='hidden' name='_method' value='patch'>")
          // set file dropzone form to submit to version attachments path
          form.action = `/admin/approval_events/${firstAttachment.approval_event_id}/attachments`
        }
      }
    } else if (firstAttachment.resource_type == 'ApprovalVersion') {
      this.ApprovalVersionSubmitButtonTarget.disabled = false

      if (this.ApprovalVersionFormTarget.action.startsWith("/admin/approval_versions/")) {
        // set new approval version form to submit to edit version
        this.ApprovalVersionFormTarget.action = `/admin/approval_versions/${firstAttachment.approval_version_id}`
        this.ApprovalVersionFormTarget.insertAdjacentHTML( 'afterbegin', "<input type='hidden' name='_method' value='patch'>")
        // set file dropzone form to submit to version attachments path
        form.action = `/admin/approval_versions/${firstAttachment.approval_version_id}/attachments`
      }
    }
  }

}