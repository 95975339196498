import { Controller } from "@hotwired/stimulus"
import Sortable from 'sortablejs'

export default class extends Controller {
  static get targets () {
    return [
    ]
  }

  connect() {
    Sortable.create(this.element, {
      animation: 150,
      ghostClass: '!bg-blue-200',
      onUpdate: this.onEnd.bind(this)
    })
  }

  onEnd(evt) {
    let id = evt.item.dataset.id
    let newPosition = evt.newIndex
    let data = new FormData()
    data.append("position", newPosition)

    $.ajax({
      url: this.data.get("url").replace(":id", id),
      type: "PATCH",
      data: data,
      processData: false,
      contentType: false,
    })
  }

}