import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr";

export default class extends Controller {

  initialize() {
    this.inline = this.data.get("flatpickrInline")
    this.initDatetimePicker()
  }

  initDatetimePicker() {
    $(".flatpickr").each(function(_, elem) {
      flatpickr(elem, {
        enableTime: true,
        altInput: true,
        altFormat: "F j, Y, h:i K",
        dateFormat: "Y-m-d H:i",
        defaultDate: elem.value,
        inline: this.inline
      });
    }.bind(this))
  }

}