import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets () {
    return [
      "RoleCount",
      "VoteCount"
    ]
  }

  get minutesController() {
    var elem = document.getElementById(`item-minutes-${this.itemId}`)
    return this.application.getControllerForElementAndIdentifier(elem, "item-minutes")
  }

  initialize() {
    this.form = this.element
    this.resourceType = this.data.get("resourceType")
    this.resourceId = this.data.get("resourceId")
    this.itemId = this.data.get("itemId")
    this.isAmendment = JSON.parse(this.data.get("isAmendment"))
    this.roleOptions = JSON.parse(this.data.get("roleOptions"))
    this.voteOptions = JSON.parse(this.data.get("voteOptions"))

    $(this.form).on('input', 'input, select, textarea', (e) => {
      if (this.isAmendment !== true) {
        this.onInputChange(e)
      } else {
        this.updateTotals()
      }
    })

    this.updateTotals()
  }

  onInputChange(e) {
    var votableId = $(e.target).data("votableId")
    var votableType = $(e.target).data("votableType")
    var formUrl = `/admin/${votableType}s/${votableId}`

    fetch(formUrl, {
      method: "PUT",
      credentials: "same-origin",
      headers:  {
        "Content-Type": "application/json",
        "Accept": "application/json"
      },
      body: JSON.stringify({ [votableType]: {
        admin_user_id: $(e.target).data("adminUserId"),
        selection: e.target.value
      }})
    })
      .then(response => response.json())
      .then(response => {
        this.updateTotals()
        this.minutesController.updateSummaryWithResponse(response)
      })
      .catch(console.error)
  }

  updateTotals() {
    this.roleOptions.map((roleOption) => {
      var roleInputs = $(this.form).find(`input:checked[data-votable-type='voter_role'][value='${roleOption}']`)
      var countTarget = this.RoleCountTargets.find((target) => $(target).data("selection") == roleOption)
      $(countTarget).html(roleInputs.length)
    })
    this.voteOptions.map((voteOption) => {
      var voteInputs = $(this.form).find(`input:checked[data-votable-type='voter_vote'][value='${voteOption}']`)
      var countTarget = this.VoteCountTargets.find((target) => $(target).data("selection") == voteOption)
      $(countTarget).html(voteInputs.length)
    })
  }

}