import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.colorInputs = this.element.querySelectorAll('input[type="color"]')

    this.colorInputs.forEach((input) => {
      // set listener to display the hex value of the color input in the hint below the input
      input.addEventListener('input', (event) => {
        const color = event.target.value
        const hint = event.target.parentElement.querySelector('.inline-hints')

        hint.textContent = color
      })

      // add button to swap input from color to text (to allow pasting hex values)
      const swapButton = input.closest("div.input-wrapper").querySelector("a.swap-button")

      swapButton.addEventListener('click', (event) => {
        event.preventDefault()

        if (input.type === 'color') {
          input.type = 'text'
          swapButton.textContent = 'Use Color Picker'
        } else {
          input.type = 'color'
          swapButton.textContent = 'Enter RGB Hex value'
        }
      })
    })
  }

}
