import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    // hide 'Other' textarea by default
    $('body').on('modal_dialog:before_open', function() {
      $("label").filter(":contains('Reason')").attr('style','display:none !important');
      $("label").filter(":contains('Reason Text')").attr('style','display:none !important');
      $("input[name='Reason Text']").attr('style','display:none !important');
      $("label").filter(":contains('Send Rejection Notification')").attr('style','display:block !important');
    })

    // handle select changing to 'Other' to show textarea
    $('body').on('modal_dialog:after_open', function() {
      $("label").filter(":contains('Reason')").hide()
      $("input[name='Send Rejection Notification To Resident']").prop('checked', true);

      $("select[name='reason']").change(function(e) {
        if (e.target.value == 'Other') {
          $("label").filter(":contains('Reason Text')").attr('style','display:block !important');
          $("input[name='Reason Text']").attr('style','display:block !important');
        } else {
          $("label").filter(":contains('Reason Text')").attr('style','display:none !important');
          $("input[name='Reason Text']").attr('style','display:none !important');
        }
      })

      return
    })
  }

  onRejectClick(e) {
    e.stopPropagation()
    e.preventDefault()

    const commentId = e.currentTarget.dataset.id

    ActiveAdmin.modal_dialog('Select rejection reason: ', { reason: ['Profanity', 'Inflammatory, threatening or abusive language', 'Personal attacks', 'False identification of the commenter', 'Language or concepts deemed to be offensive or demonstrably false', 'Language promoting or advertising businesses, services, events, organization or political candidates or issues', 'Comments directed at prior commenters or the public, rather than the decision makers', 'Other'], 'Reason Text': 'text', 'Send Rejection Notification To Resident': 'checkbox' }, function(inputs) {
      var commentBody = { reason: inputs.reason, reason_text: inputs['Reason Text'], send_email_to_citizen: inputs['Send Rejection Notification To Resident'] }

      $.ajax({
        url: "/admin/comments/"+commentId+"/reject",
        type: 'POST',
        data: { comment: commentBody },
        success: function() {
          window.location.reload()
          return true
        },
        error: function() {
          window.location.reload()
          return true
        }
      })

      return
    })
  }
}