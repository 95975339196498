import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'
import Sortable from "sortablejs"

export default class extends Controller {
  static values = {
    reorderUrl: String,
    conversionErrorAction: String,
  }

  static targets = ["ListItem", "ListItemTemplate", "TemplateFiletypeSvg", "TemplateUploadedTime", "EditNameButton", "NameInput", "NameText", "ControlsButton", "ControlsDropdown", "ConvertingStatus", "ViewFileLink", "DeleteFileLink"]

  connect() {
    this.startingEmpty = this.ListItemTargets.length == 0
    this.conversionIntervals = {}

    new Sortable(this.element, {
      group: 'file_list_group',
      ghostClass: '!bg-blue-200',
      animation: 150,
      onEnd: this.onSortEnd.bind(this)
    })

    $(document).click(() => {
      this.ControlsDropdownTargets.map((target) => target.classList.add("hidden"))
    })
  }

  callControllerMethod(controllerAndMethod, payload = null, formElement = null, id = null) {
    const parts = controllerAndMethod.split('->')
    const controller = parts[0]
    const method = parts[1]
    let controllerElement
    if (id) {
      const controllerElems = document.querySelectorAll(`[data-controller="${controller}"]`)
      controllerElement = Array.from(controllerElems).find((elem) => elem.id == id)
    } else {
      controllerElement = document.querySelectorAll(`[data-controller="${controller}"]`)[0]
    }
    const controllerInstance = this.application.getControllerForElementAndIdentifier(controllerElement, controller)
    if (controllerInstance) {
      if (payload && formElement) {
        controllerInstance[method](payload, formElement)
      } else if (payload) {
        controllerInstance[method](payload)
      } else if (formElement) {
        controllerInstance[method](formElement)
      } else {
        controllerInstance[method]()
      }
    }
  }

  async onSortEnd(e) {
    const attachmentId = e.item.dataset.id || e.item.dataset.attachmentId
    let newPosition = e.newIndex

    if (this.startingEmpty) {
      newPosition -= 1
    }

    const request = new FetchRequest("PUT", `${this.reorderUrlValue}?attachment_id=${attachmentId}&new_position=${newPosition}`, { responseKind: 'json' })
    const response = await request.perform()

    if (response.ok) {
      // do nothing
    } else {
      const responseJson = await response.json

      alert("There was an error moving this item: ", responseJson.error)
    }
  }

  onEditNameClick(e) {
    e.preventDefault()
    const button = e.currentTarget
    const attachmentId = button.dataset.attachmentId
    const nameInput = this.NameInputTargets.find((target) => target.dataset.attachmentId == attachmentId)
    const nameText = this.NameTextTargets.find((target) => target.dataset.attachmentId == attachmentId)

    button.classList.add("hidden")
    nameText.classList.add("hidden")
    nameInput.classList.remove("hidden")
    nameInput.focus()
    const val = nameInput.value; //store the value of the element
    nameInput.value = ''; //clear the value of the element
    nameInput.value = val;
  }

  async onDeleteClick(e) {
    e.preventDefault()
    e.stopPropagation()

    if (window.confirm("Are you sure you want to delete this file?")) {
      const request = new FetchRequest("DELETE", e.target.getAttribute("href"), { responseKind: 'json' })
      const response = await request.perform()

      if (response.ok) {
        const fileListItem = e.target.closest('li')
        fileListItem.remove()
      } else {
        console.warn('Error deleting file: ', response.body)
        alert("There was an error deleting the file.")
      }
    }
  }

  onNameInputChange(e) {
    e.preventDefault()

    if (e.type == 'keyup' && e.keyCode == 13) {
      e.stopImmediatePropagation()
      e.target.blur()
    }

    if (e.type == 'blur') {
      this.submitUserFilenameInput(e.target)
    }
  }

  hideUserFilenameInput(input, value) {
    const button = this.EditNameButtonTargets.find((target) => target.dataset.attachmentId == input.dataset.attachmentId)
    const nameText = this.NameTextTargets.find((target) => target.dataset.attachmentId == input.dataset.attachmentId)

    button.classList.remove("hidden")
    nameText.innerHTML = value
    nameText.classList.remove("hidden")
    input.classList.add("hidden")
  }

  async submitUserFilenameInput(input) {
    const request = new FetchRequest("PUT", input.dataset.url, { body: JSON.stringify({ attachment: { user_filename: input.value } }), responseKind: 'json' })
    const response = await request.perform()

    if (response.ok) {
      const responseJson = await response.json

      this.hideUserFilenameInput(input, responseJson.filename)
    } else {
      const responseJson = await response.json

      alert(responseJson.errors)
    }
  }

  onControlsClick(e) {
    e.preventDefault()
    e.stopPropagation()

    const controls = e.currentTarget
    const attachmentId = controls.dataset.attachmentId
    const dropdown = this.ControlsDropdownTargets.find((target) => target.dataset.attachmentId == attachmentId)
    dropdown.classList.remove("hidden")
  }

  onAppendListItem(attachment) {
    const newListItem = this.ListItemTemplateTarget.cloneNode(true)
    newListItem.setAttribute('data-attachment-id', attachment.id)
    newListItem.querySelector('[data-file-list-target="NameText"]').innerHTML = `${attachment.filename} (${attachment.file_byte_size} MB)`
    newListItem.querySelector('[data-file-list-target="NameText"]').setAttribute('data-attachment-id', attachment.id)
    newListItem.querySelector('[data-file-list-target="NameInput"]').value = attachment.filename
    newListItem.querySelector('[data-file-list-target="NameInput"]').setAttribute('data-attachment-id', attachment.id)
    newListItem.querySelector('[data-file-list-target="EditNameButton"').setAttribute('data-attachment-id', attachment.id)
    newListItem.querySelector('[data-file-list-target="TemplateUploadedTime"]').innerHTML = attachment.uploaded
    newListItem.querySelector('[data-file-list-target="ControlsButton"]').setAttribute('data-attachment-id', attachment.id)
    newListItem.querySelector('[data-file-list-target="ControlsDropdown"]').setAttribute('data-attachment-id', attachment.id)
    const template = document.createElement('template')
    template.innerHTML = attachment.ui_icon
    template.content.firstChild.classList.add("mr-2", "w-6", "h-6")
    newListItem.querySelector('[data-file-list-target="TemplateFiletypeSvg"]').replaceWith(template.content.firstChild)
    newListItem.querySelector('[data-file-list-target="ViewFileLink"]').setAttribute('href', attachment.url)
    if (attachment.conversion_status == "converting") {
      newListItem.querySelector('[data-file-list-target="ConvertingStatus"]').classList.remove("hidden")
      this.conversionIntervals[attachment.id] = setInterval(() => {
        this.checkConversionStatus(attachment.id)
      }, 2000)
    } else {
      newListItem.querySelector('[data-file-list-target="ConvertingStatus"]').classList.add("hidden")
    }
    newListItem.setAttribute('data-reorder-url', attachment.reorder_path)
    newListItem.querySelector('[data-file-list-target="NameInput"]').setAttribute('data-url', attachment.rename_path)
    newListItem.querySelector('[data-file-list-target="DeleteFileLink"]').setAttribute('href', attachment.delete_path)
    newListItem.classList.remove("hidden")

    this.element.classList.remove("hidden")
    this.element.append(newListItem)

    const count = $("#item-attachments-count").html()
    $("#item-attachments-count").html(parseInt(count) + 1)
  }

  async checkConversionStatus(attachment_id) {
    const request = new FetchRequest("GET", `/admin/attachments/${attachment_id}`, { responseKind: 'json' })
    const response = await request.perform()

    if (response.ok) {
      const responseJson = await response.json

      if (responseJson.conversion_status == "converted") {
        const attachmentItem = this.element.querySelector(`.file-list-item[data-attachment-id="${responseJson.id}"]`)
        attachmentItem.querySelector('[data-file-list-target="ConvertingStatus"]').classList.add("hidden")
        attachmentItem.querySelector('[data-file-list-target="NameText"]').innerHTML = `${responseJson.filename} (${responseJson.file_byte_size} MB)`
        attachmentItem.querySelector('[data-file-list-target="ViewFileLink"]').setAttribute('href', responseJson.url)
        clearInterval(this.conversionIntervals[responseJson.id])
      }
    } else {
      if (this.conversionErrorActionValue != "") {
        this.callControllerMethod(this.conversionErrorActionValue)
      }

      if (window.Sentry) {
        Sentry.captureException('Error checking PDF Conversion status: ' + e)
      }

      this.conversionIntervals.keys().map((key) => {
        clearInterval(this.conversionIntervals[key])
      })
    }
  }


}