import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets () {
    return [
      "Form",
      "FileInput",
      "FileTemplate",
      "ProcessButton",
      "UploadButton",
      "isDraftInput"
    ]
  }

  initialize() {
    this.formUrl = this.data.get("formUrl")
    this.meetingId = this.data.get("meetingId")

    $(this.FileInputTarget).on('change', this.onFileUploadChange.bind(this))
  }

  onUploadClick(e) {
    $(this.FileInputTarget).trigger('click')
  }

  onFileUploadChange(e) {
    $(this.UploadButtonTarget).html("Uploading...")

    var formData = new FormData(this.FormTarget)
    formData.meeting_id = this.meetingId
    formData.append('file', e.target.value)

    fetch(this.FormTarget.action, {
      method: this.FormTarget.method,
      credentials: "same-origin",
      body: formData
    }).then(response => response.json())
    .then(response => {
      $(this.UploadButtonTarget).html("Upload")
      $(e.target).val(null)
      this.updateListFromResponse(response)
    })
    .catch(console.error)
  }

  onDelete(e) {
    e.preventDefault()

    var choice = confirm("Are you sure you want to delete this file?")

    if (choice == true) {
      var fileId = $(e.target).data("id")
      fetch(`/admin/minutes_files/${fileId}`, {
        credentials: "same-origin",
        method: "DELETE"
      }).then(response => response.json())
      .then(response => {
        this.updateListFromResponse(response)
      })
      .catch(console.error)
    }
  }

  onProcess(e) {
    $(this.ProcessButtonTarget).html("Processing...")
    e.target.disabled = true

    fetch(`/admin/minutes_files`, {
      method: "POST",
      credentials: "same-origin",
      headers:  {
        "Content-Type": "application/json",
        "Accept": "application/json"
      },
      body: JSON.stringify({ minutes_file: {
        meeting_id: this.meetingId,
        draft: this.isDraftInputTarget.checked
      } })
    }).then(response => response.json())
    .then(response => {
      $(this.ProcessButtonTarget).html("Process")
      e.target.disabled = false
      this.updateListFromResponse(response)
    })
    .catch(console.error)
  }

  updateListFromResponse(response) {
    if (response.minutes_files.length > 0) {
      $(".minutes-file-item").remove()
      var publishedFile = response.minutes_files.find((minutes_file) => minutes_file.published)
      var publishedId

      if (publishedFile) {
        publishedId = publishedFile.id
      }

      response.minutes_files.map((minutes_file) => {
        var elem = $(this.FileTemplateTarget).clone()
        $(elem).find(".file-url").attr("href", minutes_file.url)
        $(elem).data("target", null)
        $(elem).removeClass("hidden")
        $(elem).addClass("minutes-file-item")
        $(elem).find(".filename").html(minutes_file.file_name)
        $(elem).find(".updated-on").html(`&mdash; Updated on ${minutes_file.updated_on}`)
        $(elem).find(".minutes-file-delete-link").attr("href", minutes_file.destroy_path)
        $(elem).find(".minutes-file-delete-link").data("id", minutes_file.id)
        if (minutes_file.draft) {
          $(elem).find(".approve-toggle-container").remove()
        } else {
          if (publishedId && publishedId == minutes_file.id) {
            $(elem).find(".approve-toggle-container").removeClass("hidden")
          } else if (publishedId == null) {
            $(elem).find(".approve-toggle-container").removeClass("hidden")
          }

          if (minutes_file.published) {
            var toggleContainer = $(elem).find(".approve-toggle")[0]
            toggleContainer.classList.remove("bg-gray-200")
            toggleContainer.classList.add("bg-indigo-600")
            toggleContainer.dataset.action = "click->minutes-file#onUnapprove"
            toggleContainer.dataset.minutesFileId = minutes_file.id
            var toggle = $(elem).find(".approve-toggle-toggle")[0]
            toggle.classList.remove("translate-x-0")
            toggle.classList.add("translate-x-5")
            toggle.setAttribute(`data-minutes-file-${minutes_file.id}-toggle`, "")
            $(elem).find(".approve-toggle-container")[0].setAttribute(`data-minutes-file-${minutes_file.id}-toggle`, "")
            $(elem).find(".minutes-file-delete-link").addClass("hidden")
          } else {
            var toggleContainer = $(elem).find(".approve-toggle")[0]
            toggleContainer.dataset.action = "click->minutes-file#onApprove"
            toggleContainer.dataset.minutesFileId = minutes_file.id
            var toggle = $(elem).find(".approve-toggle-toggle")[0]
            toggle.setAttribute(`data-minutes-file-${minutes_file.id}-toggle`, "")
            $(elem).find(".approve-toggle-container")[0].setAttribute(`data-minutes-file-${minutes_file.id}-toggle`, "")
          }
        }
        $("#minutes-files-list").append(elem)
      })
    } else {
      $(".minutes-file-item").remove()
      $("#minutes-files-list").append("<span class=\"italic\">There are no Minutes Files.</span>")
    }
  }

  onApprove(e) {
    e.stopPropagation()

    var minutesFileId = e.target.dataset.minutesFileId
    e.target.classList.remove("bg-gray-200")
    e.target.classList.add("bg-indigo-600")
    var toggle = $("[data-minutes-file-"+minutesFileId+"-toggle]")[0]
    toggle.classList.remove("translate-x-0")
    toggle.classList.add("translate-x-5")
    var parent = $(e.target).closest(".minutes-file-item")
    $(parent).find(".minutes-file-delete-link").addClass("hidden")

    // hide other toggle switches
    $(`.approve-toggle-container`).each(function() {
      if (this.dataset.minutesFileId !== minutesFileId) {
        this.classList.add('hidden')
      }
    })

    fetch(`/admin/minutes_files/${minutesFileId}`, {
      method: "PATCH",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      },
      body: JSON.stringify({
        minutes_file: {
          published: true
        }
      })
    }).then(response => response.json())
      .then(response => {
        this.updateListFromResponse(response)
      })
      .catch(console.error)
  }

  onUnapprove(e) {
    e.stopPropagation()

    var minutesFileId = e.target.dataset.minutesFileId
    e.target.classList.add("bg-gray-200")
    e.target.classList.remove("bg-indigo-600")
    var toggle = $("[data-minutes-file-" + minutesFileId + "-toggle]")[0]
    toggle.classList.add("translate-x-0")
    toggle.classList.remove("translate-x-5")
    var parent = $(e.target).closest(".minutes-file-item")
    $(parent).find(".minutes-file-delete-link").removeClass("hidden")

    // show other toggle switches
    $(`.approve-toggle-container`).each(function () {
      if (this.dataset.minutesFileId !== minutesFileId) {
        this.classList.add('hidden')
      }
    })

    fetch(`/admin/minutes_files/${minutesFileId}`, {
      method: "PATCH",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      },
      body: JSON.stringify({
        minutes_file: {
          published: false
        }
      })
    }).then(response => response.json())
      .then(response => {
        this.updateListFromResponse(response)
      })
      .catch(console.error)
  }

}