import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets () {
    return [
      "LiveStreamUrlInput",
      "LiveStreamEmbedCodeInput",
      "DeleteButton",
    ]
  }

  initialize() {
    this.newStreamUrl = this.data.get("newStreamUrl")
  }

}