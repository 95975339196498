import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from "@rails/request.js"

export default class extends Controller {
  static values = {
    statusUrl: String,
    status: String,
    redirectUrl: String
  }

  connect() {
    if (this.statusValue === "completed") {
      window.location = this.redirectUrlValue
    } else {
      setTimeout(() => {
        this.fetchMeetingImportStatus()
      }, 1000)
    }
  }

  fetchMeetingImportStatus() {
    const request = new FetchRequest("GET", this.statusUrlValue, { responseKind: 'turbo-stream' })
    request.perform()
  }
}