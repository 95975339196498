import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets () {
    return []
  }

  initialize() {
    this.savePath = this.data.get("savePath")
    this.method = this.data.get("method") || "PUT"
    this.parentKey = this.data.get("parentKey")
    this.objectKey = this.data.get("objectKey")
    this.errorElement = document.createElement("div")
    this.errorElement.classList.add("mt-2", "text-red-500")

    this.onChange = debounce(this.onChange.bind(this), 500)
  }

  onChange(e) {
    e.target.classList.remove("border-red-500")
    $(this.errorElement).detach()

    var body = {}
    body[this.parentKey] = {}
    body[this.parentKey][this.objectKey] = e.target.value

    fetch(this.savePath, {
      method: this.method,
      credentials: "same-origin",
      headers:  {
        "Content-Type": "application/json",
        "Accept": "application/json"
      },
      body: JSON.stringify(body)
    })
      .then(response => response.json())
      .then(response => {
        if (response.errors) {
          e.target.classList.add("border-red-500")
          this.errorElement.innerHTML = response.errors[this.objectKey].join(", ")
          $(e.target).parent().append(this.errorElement)
        }
      })
      .catch(console.error)
  }

}