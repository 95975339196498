import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["Link"]

  connect() {
    const projectEditContainer = document.querySelector("#project-edit-container")
    const hashLoaded = JSON.parse(projectEditContainer.dataset.hashLoaded)

    if (window.location.hash !== '' && !hashLoaded) {
      this.loadTabContent(window.location.hash)

      projectEditContainer.dataset.hashLoaded = true
    }
  }

  onClick(e) {
    let url = new URL(document.URL)
    url.hash = `#${e.target.dataset.navParam}`

    document.location.href = url.href
  }

  loadTabContent(hash) {
    const link = this.LinkTargets.find((target) => target.dataset.navParam == hash.replace("#", ""))
    link.click()
  }

}