import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from "@rails/activestorage"

export default class extends Controller {
  static values = {
    url: String
  }
  static targets = ["Image", "ImagePlaceholder", "ImageLabel", "Input"]

  initialize() {
    this.InputTarget.addEventListener('change', this.onChange.bind(this))
  }

  onChange(e) {
    const file = this.InputTarget.files[0];
    const upload = new DirectUpload(file, this.urlValue)

    upload.create((error, blob) => {
      if (error) {
        console.log(error)
        return
      }

      // append a hidden field w/ blob.signed_id value
      let blobIdElem = document.createElement("input")
      blobIdElem.setAttribute("type", "hidden")
      blobIdElem.setAttribute("value", blob.signed_id)
      blobIdElem.name = this.InputTarget.name
      this.InputTarget.form.appendChild(blobIdElem)

      this.ImageTarget.src = '/rails/active_storage/blobs/' + blob.signed_id + '/' + blob.filename;

      if (this.ImageTarget.classList.contains("hidden")) {
        this.ImageTarget.classList.remove("hidden")
        this.ImagePlaceholderTarget.classList.add("hidden")
        this.ImageLabelTarget.classList.remove("p-2.5")
      }
    })
  }

}