import { Controller } from "@hotwired/stimulus"
import Trix from 'trix'

export default class extends Controller {
  static targets = ["DescriptionInput", "TitleInput"]
  static values = {
    templatesList: Array
  }

  connect() {
    // not sure if needed? pulled from example
    window.Trix = Trix
    Trix.config.toolbar.getDefaultHTML = window.trixToolbarDefaultHtml

    document.addEventListener('trix-initialize', this.addToolbarTemplateButton.bind(this), { once: true})

    this.trixEditor = this.element.querySelector("trix-editor")
  }

  addToolbarTemplateButton() {
    const toolbarRow = this.element.querySelector('.trix-button-row')
    toolbarRow.classList.add('pb-2')
    const toolbarSibling = toolbarRow.children[toolbarRow.children.length - 2]
    const select = document.createElement('select')
    select.setAttribute('class', 'ml-6 text-xs p-1 pr-8 font-medium border-gray-300 shadow rounded-sm')
    select.dataset.aysIgnore = 'true'
    select.dataset.action = "change->decision-making-criteria-template#onTemplateChange"
    const placeholderOption = document.createElement('option')
    placeholderOption.setAttribute('value', '')
    placeholderOption.setAttribute('selected', 'selected')
    placeholderOption.classList.add('font-medium')
    placeholderOption.innerHTML = 'Select Template'
    select.appendChild(placeholderOption)
    this.templatesListValue.map((template) => {
      const option = document.createElement('option')
      option.setAttribute('value', template[1])
      option.innerHTML = template[0]
      select.appendChild(option)
    })

    toolbarRow.insertBefore(select, toolbarSibling)
  }

  onTemplateChange(event) {
    const value = event.currentTarget.value

    if (value) {
      this.TitleInputTarget.value = event.currentTarget.selectedOptions[0].innerHTML
      this.setEditorHtml(value)
      this.removeEditorExtraSpacing()
    }
  }

  setEditorHtml(html) {
    // this.trixEditor.editor.setSelectedRange([0, 0])
    // this.trixEditor.editor.insertHTML(html)
    this.trixEditor.innerHTML = html
  }

  // happens within trix internal HTML parser where it adds extra <br> tags
  // bullshit non-answer and close: https://github.com/basecamp/trix/issues/194
  removeEditorExtraSpacing() {
    const editorHtml = this.trixEditor.innerHTML
    // needed b/c it was turning HTML of <br><br></div> into <br><br><br></div> and rendering with extra spacing
    // regex: look for 2 or more <br> tags followed by 0 or more newlines or spaces followed by a </div> tag
    // used Bing Chat to come up with that ^ regex
    const replacedHtml = editorHtml.replace(/(<br\s*\/?>){2,}(\s*)(?=<\/div>)/g, '<br><br></div>')
    this.trixEditor.innerHTML = replacedHtml
  }

}