import { Controller } from "@hotwired/stimulus"
import Sortable from 'sortablejs'

export default class extends Controller {
  static get targets() {
    return ["errorTitle", "errorFilesList", "inputTitle", "Form"]
  }

  connect() {
    this.versionId = this.data.get("versionId")

    Sortable.create($('[data-controller="file-list"]')[0], {
      animation: 150,
      ghostClass: '!bg-blue-200',
      onUpdate: (evt) => {
        var url = evt.item.dataset.reorderUrl
        var attachmentId = evt.item.dataset.id
        var newPosition = evt.newIndex

        $.ajax({
          url: url + '?attachment_id='+attachmentId+'&new_position='+newPosition,
          method: 'PUT',
          success: function(body) {
            // do nothing
          }
        })
      }
    })

    this.debouncedTitleChange = debounce(this.onTitleChange.bind(this), 250)
  }

  onPreFileUpload() {
    if (this.hasErrorFilesListTarget) {
      this.errorFilesListTarget.classList.add("hidden")
    }
  }

  onPostFileUpload(data, fileDropzoneForm) {
    if (this.hasFormTarget && this.FormTarget.action.endsWith("/admin/approval_versions")) {
      // set new approval form to submit to edit version
      this.FormTarget.action = `/admin/approval_versions/${data.approval_version_id}`
      this.FormTarget.insertAdjacentHTML( 'afterbegin', "<input type='hidden' name='_method' value='patch'>")
      // set file dropzone form to submit to version attachments path
      fileDropzoneForm.action = `/admin/approval_versions/${data.approval_version_id}/attachments`
    }
  }

  onReviewersChange(e) {
    var reviewers = []

    $("input:checkbox[name='approval_version[reviewers][]']:checked").each(function() {
      reviewers.push($(this).val())
    })

    fetch("/admin/approval_versions/all/draft_reviewers_list", {
      method: "PUT",
      credentials: "same-origin",
      headers:  {
        "Content-Type": "application/json",
        "Accept": "application/json",
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      body: JSON.stringify({ 'approval_version': { reviewers: reviewers }})
    })
      .catch(console.error)
  }

  onTitleChange(e) {
    console.log('on titel change', )
    e.target.classList.remove("border-red-500")
    if (this.hasErrorTitleTarget) {
      this.errorTitleTarget.classList.add("hidden")
    }

    this.inputTitleTarget.value = e.target.value

    fetch("/admin/approval_versions/all/draft_title", {
      method: "PUT",
      credentials: "same-origin",
      headers:  {
        "Content-Type": "application/json",
        "Accept": "application/json",
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      body: JSON.stringify({ 'approval_version': { title: e.target.value }})
    })
      .catch(console.error)
  }

  onSubmit(e) {
    e.target.disable = true
    e.target.innerHTML = "Processing..."

    this.FormTarget.submit()
  }

}