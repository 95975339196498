import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets() {
    return [
      "pendingElement",
      "resultElement",
      "fileInput",
    ]
  }

  initialize() {
    this.packetAttachment = this.data.get('json')
    this.meetingId        = this.data.get('meetingId')
    if (this.packetAttachment == "") {
      this.packetAttachment = null
    } else {
      this.packetAttachment = JSON.parse(this.packetAttachment)

      if (this.packetAttachment.id && this.packetAttachment.conversion_status == "converting" && this.packetAttachment.conversion_error == null) {
        setTimeout(() => {
          this.checkConversionStatus()
        }, 5000)
      }
    }
  }

  checkConversionStatus() {
    const url = `/admin/packet_attachments/${this.packetAttachment.id}.json`
    fetch(url, {
      method: 'GET',
      credentials: "same-origin",
      cache: 'no-cache'
    })
      .then(response => response.text())
      .then(html => {
        this.element.outerHTML = html
      }).catch((e) => {
        alert("Checking for Packet PDF conversion status failed.\nPlease try refreshing.\n\nIf this problem persists, please contact support.")
      })
  }

  onFileChange(e) {
    const method = this.packetAttachment ? 'PUT' : 'POST'
    const url    = this.packetAttachment ? `/admin/packet_attachments/${this.packetAttachment.id}` : '/admin/packet_attachments'
    let formData = new FormData()
    formData.append("packet_attachment[user_overridden_file]", this.fileInputTarget.files[0])
    if (method == 'POST') {
      formData.append('meeting_id', this.meetingId)
    }

    this.pendingElementTarget.classList.toggle("-hidden", false)
    this.resultElementTarget.classList.toggle("-hidden", true)

    fetch(url, {
      method: method,
      body: formData,
      credentials: "same-origin",
      cache: 'no-cache'
    })
      .then(response => response.text())
      .then(html => {
        this.element.innerHTML = html
      }).catch((e) => {
        alert("Error uploading manually overridden packet PDF file.\nPlease try refreshing the page and trying again.\n\nIf this problem persists, please contact support.")
      })
  }

  onProcessClick(e) {
    const unpublishedItems = this.data.get('unpublishedItems')
    const singleItem       = this.data.get('singleItem')

    if (unpublishedItems == "true") {
      if (window.confirm("You have unpublished items. Are you sure you want to process this Packet File as-is?")) {
        // let it proceed
      } else {
        e.preventDefault()
        e.stopPropagation()
      }
    }

    if (singleItem == "true") {
      if (window.confirm("You have only one file set for this packet. Are you sure you want to process this Packet File as-is?")) {
        // let it proceed
      } else {
        e.preventDefault()
        e.stopPropagation()
      }
    }
  }

}