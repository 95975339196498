import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['StaffResponsesEmptyMessage']

  connect() {
    document.addEventListener("turbo:before-stream-render", this.onTurboStream.bind(this))
  }

  onTurboStream(e) {
    if (e.target.outerHTML.includes('target="staff_responses"')) {
      if (this.hasStaffResponsesEmptyMessageTarget) {
        this.StaffResponsesEmptyMessageTarget.classList.add("hidden")
      }
    }
  }
}