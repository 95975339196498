import { Controller } from "@hotwired/stimulus"
import { useDebounce } from "stimulus-use"

export default class extends Controller {
  static debounces = ['onKeyup']
  static targets = ["form"]

  initialize() {
    useDebounce(this)
    this.editor = null

    this.assignKeyupHandler()
    this.assignSubmitEndHandler()
  }

  assignKeyupHandler() {
    let editors = this.formTarget.getElementsByTagName("trix-editor")
    Array.from(editors).map((editor) => {
      editor.addEventListener("keyup", (e) => {
        this.onKeyup(e)
      })
      this.editor = editor
    })
  }

  assignSubmitEndHandler() {
    this.formTarget.addEventListener("turbo:submit-end", this.onSubmitEnd.bind(this))
  }

  onKeyup(e) {
    this.editor.classList.add("auto-saving")

    this.formTarget.requestSubmit()
  }

  onSubmitEnd(e) {
    this.editor.classList.remove("auto-saving")
  }

}