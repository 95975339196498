import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets() {
    return [
      "ModalContainer",
      "Heading",
      "ItemIdInput",
      "InstructionText",
      "SubmitButton",
    ]
  }

  connect() {
    $(".admin-list-item-controls-move").click(this.onMoveClick.bind(this))
    $(".admin-list-item-controls-copy-and-move").click(this.onCopyAndMoveClick.bind(this))
  }

  toggleModal(e) {
    e.preventDefault()
    this.ModalContainerTarget.classList.toggle("hidden")
  }

  onMoveClick(e) {
    e.preventDefault()

    var parentDropdown = $(e.target).closest(".admin-list-item-controls-dropdown")
    var itemId = $(parentDropdown).attr('id').replace("admin-list-controls-dropdown-", "")

    this.ItemIdInputTarget.value = itemId
    this.toggleModal(e)
    this.HeadingTarget.innerHTML = "Move Item"
    this.SubmitButtonTarget.innerHTML = "Move"
    this.InstructionTextTarget.classList.add("hidden")
    setTimeout(function() {
      $("#meeting-move-form").attr("action", "/admin/items/"+itemId)
    }, 100)
  }

  onCopyAndMoveClick(e) {
    e.preventDefault()

    var parentDropdown = $(e.target).closest(".admin-list-item-controls-dropdown")
    var itemId = $(parentDropdown).attr('id').replace("admin-list-controls-dropdown-", "")

    this.ItemIdInputTarget.value = itemId
    this.toggleModal(e)
    this.HeadingTarget.innerHTML = "Copy & Move Item"
    this.SubmitButtonTarget.innerHTML = "Copy & Move"
    this.InstructionTextTarget.classList.remove("hidden")
    setTimeout(function() {
      $("#meeting-move-form").attr("action", "/admin/items/"+itemId+"/copy_and_move")
    }, 100)
  }

}