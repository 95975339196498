import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"
import DirtyForm from 'dirty-form'
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static targets = [
    "Form", "AttachmentsList", "ProcessPacketButton",
    "ItemsList", "AddItemButton", "ItemListNameInput", "ItemListNameText", "ItemListEditNameButton", "ItemListControlsDropdown"
  ]

  initialize() {
    this.meetingId = this.data.get('meetingId')

    this.initDirtyForm()
    this.initItemsListSortable()
    this.initItemControlsDropdowns()
    this.initItemAddClick()
    this.initAgendaListItemDeleteClick()
    this.initDummyInputCopying()
    this.initHideDropdownControlsOnClick()

    this.limiInputCharCount("#meeting_meeting_type", 30)
    this.limiInputCharCount("#meeting_meeting_instruction_text_home_page", 60)
    this.handleTabClickAnchorUpdate()
  }

  initDirtyForm() {
    if (this.hasFormTarget) {
      new DirtyForm(this.FormTarget, { 'message': 'You have unsaved changes. Are you sure you want to leave?'})
    }
  }

  initItemsListSortable() {
    if (this.hasItemsListTarget) {
      new Sortable(this.ItemsListTarget, {
        group: 'items_list_group',
        ghostClass: '!bg-blue-200',
        animation: 150,
        onEnd: this.onSortEnd.bind(this)
      })
    }
  }

  async onSortEnd(event) {
    const itemId = event.item.dataset.id
    const newPosition = event.newIndex
    const reorderUrl = event.item.dataset.reorderUrl

    const request = new FetchRequest('PUT', `${reorderUrl}?item_id=${itemId}&new_position=${newPosition}`, { responseKind: 'json' })
    const response = await request.perform()

    if (response.ok) {
      // do nothing
    } else {
      let responseJson = await response.json

      alert(`There was an error moving this item: ${responseJson.error}`)
    }
  }

  initItemControlsDropdowns() {
    $(".admin-list-item-controls").click(function(e) {
      e.preventDefault();
      e.stopPropagation();
      var controls = $(e.target).closest(".admin-list-item-controls")
      var itemId = $(controls).data('id')
      var formIsUploading = $('form.meeting').data('isUploading')
      if (!formIsUploading) {
        $("#admin-list-controls-dropdown-"+itemId).addClass("-visible")
      }
    })
  }

  initAgendaListItemDeleteClick() {
    $("#admin-list-item-controls-delete.admin-list-item-item").click(function(e) {
      e.preventDefault()

      if (window.confirm("Are you sure you want to delete this item?")) {
        var target = $(e.target)
        if ($(target)[0].tagName == "svg") {
          target = $(target).closest("button")
        }
        var itemId = $(target).data('id')

        $.ajax({
          url: "/admin/agenda_list_items/"+itemId,
          method: 'DELETE',
          dataType: "json",
          success: function(json) {
            window.location.reload()
          }
        })
      }
    })
  }

  initItemAddClick() {
    // prevent adding new item while uploading
    $(".meeting-add-item-button").click(function(e) {
      if ($(e.target).hasClass('-disabled')) {
        e.preventDefault()
      }
    })
  }

  initDummyInputCopying() {
    // update parent/first meeting form hidden inputs using "dummy" inputs at bottom (below other form b/c can't embed)
    $("#dummy_meeting_public_meeting_information_url").on('input', function(e) {
      $("#meeting_public_meeting_information_url").val(e.target.value);
    })
    $("#dummy_meeting_live_stream_url").on('input', function(e) {
      $("#meeting_live_stream_url").val(e.target.value);
    })
    $("#dummy_meeting_live_stream_embed_code").on('input', function(e) {
      $("#meeting_live_stream_embed_code").val(e.target.value);
    })
    $("#dummy_meeting_how_to_join_remotely_text").on('input', function(e) {
      $("#meeting_how_to_join_remotely_text").val(e.target.value);
    })
    $("#dummy_meeting_meeting_instruction_text_home_page").on('input', function(e) {
      $("#meeting_meeting_instruction_text_home_page").val(e.target.value);
    })
    $("#dummy_meeting_meeting_type").on('input', function(e) {
      $("#meeting_meeting_type").val(e.target.value);
    })
    $("#dummy_meeting_comment_closed_reminder_email_sent").on('input', function(e) {
      $("#meeting_comment_closed_reminder_email_sent").val(e.target.checked);
    })
    $("#dummy_meeting_comment_review_reminder_email_sent").on('input', function(e) {
      $("#meeting_comment_review_reminder_email_sent").val(e.target.checked);
    })

    // meeting comment closed reminder inputs aren't part of <form/> element (at bottom)
    // update hidden field on change
    $("#meeting_comment_closed_reminder_email_sent_faux").change(function(e) {
      $("#meeting_comment_closed_reminder_email_sent").val($(e.target).is(":checked") ? '1' : '0')
    })
    $("#meeting_comment_review_reminder_email_sent_faux").change(function(e) {
      $("#meeting_comment_review_reminder_email_sent").val($(e.target).is(":checked") ? '1' : '0')
    })
    $("#meeting_post_meeting_update_ran_at_faux").change(function(e) {
      $("#meeting_post_meeting_update_ran_at").val($(e.target).is(":checked") ? new Date() : '')
    })
  }

  initHideDropdownControlsOnClick() {
    // hide open controls when clicking elsewhere
    $(document).click(() => {
      this.ItemListControlsDropdownTargets.map((target) => target.classList.remove("-visible"))
    })
  }

  limiInputCharCount(id, count) {
    $(id).on('input change', function(e) {
      if ($(e.target).val().length > count) {
        $(e.target).val($(e.target).val().substring(0, count))
      }
    })
  }

  handleTabClickAnchorUpdate() {
    setTimeout(function() {
      $(".ui-tabs-anchor").click(function(e) {
        var newHash = "#" + e.target.href.split("#")[1];

        if (newHash != '') {
          if (history.pushState) {
            history.pushState(null, null, newHash);
          }
          else {
            location.hash = newHash;
          }
        }
      })
    }, 100)
  }

  async onAddSectionLabelClick(e) {
    e.preventDefault()

    const request = new FetchRequest('post', e.currentTarget.dataset.href, { responseKind: 'json' })
    const response = await request.perform()

    if (response.ok) {
      location.reload()
    } else {
      let responseJson = await response.json

      alert(`There was an error creating this section label: ${responseJson.errors}`)
    }
  }

  onEditItemClick(e) {
    e.preventDefault()
    const button = e.currentTarget
    const resourceType = button.dataset.resourceType
    const resourceId = button.dataset.resourceId
    const nameInput = this.ItemListNameInputTargets.find((target) => target.dataset.resourceId == resourceId && target.dataset.resourceType == resourceType)
    const nameText = this.ItemListNameTextTargets.find((target) => target.dataset.resourceId == resourceId && target.dataset.resourceType == resourceType)

    button.classList.add("hidden")
    nameText.classList.add("hidden")
    nameInput.classList.remove("hidden")
    nameInput.focus()
    const val = nameInput.value; //store the value of the element
    nameInput.value = ''; //clear the value of the element
    nameInput.value = val;
  }

  onNameInputChange(e) {
    e.preventDefault()

    if (e.type == 'keyup' && e.keyCode == 13) {
      e.stopImmediatePropagation()
      e.target.blur()
    }

    if (e.type == 'blur') {
      this.submitUserFilenameInput(e.target)
    }
  }

  hideUserFilenameInput(input, value) {
    const button = this.ItemListEditNameButtonTargets.find((target) => target.dataset.resourceType == input.dataset.resourceType && target.dataset.resourceId == input.dataset.resourceId)
    const nameText = this.ItemListNameTextTargets.find((target) => target.dataset.resourceId == input.dataset.resourceId && target.dataset.resourceType == input.dataset.resourceType)

    button.classList.remove("hidden")
    nameText.innerHTML = value
    nameText.classList.remove("hidden")
    input.classList.add("hidden")
  }

  async submitUserFilenameInput(input) {
    const request = new FetchRequest("PUT", input.dataset.url, { body: JSON.stringify({ attachment: { user_filename: input.value } }), responseKind: 'json' })
    const response = await request.perform()

    if (response.ok) {
      const responseJson = await response.json

      this.hideUserFilenameInput(input, responseJson.filename)
    } else {
      const responseJson = await response.json

      alert(responseJson.errors)
    }
  }

  onAddItemClick(e) {
    window.location = e.currentTarget.dataset.href
  }

  onPreFileUpload() {
    // disable 'Update' button
    const submitButton = this.FormTarget.querySelector('input[name="commit"]')
    submitButton.setAttribute('disabled', true)
    // set uploading on form, consumed by item control popups
    this.FormTarget.setAttribute('data-is-uploading', true)
    // disable add item button
    this.AddItemButtonTarget.disabled = true

    this.ProcessPacketButtonTarget.classList.add("hidden")
  }

  onPostFileUpload() {
    const submitButton = this.FormTarget.querySelector('input[name="commit"]')
    submitButton.removeAttribute('disabled')
    console.log('submitButton', submitButton)
    this.FormTarget.setAttribute('data-is-uploading', false)
    this.AddItemButtonTarget.disabled = false

    this.ProcessPacketButtonTarget.classList.remove("hidden")
  }

}