import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from "@rails/request.js"

export default class extends Controller {
  static targets = ["StartRangeInput", "EndRangeInput", "BodyInput", "LoadingSpinner"]
  static values = { bodiesUrl: String }

  connect() {
    this.selectedBody = null

    this.fetchBodiesList()
  }

  async fetchBodiesList() {
    this.BodyInputTarget.disabled = true
    this.BodyInputTarget.innerHTML = null
    const option = document.createElement("option")
    option.value = ""
    option.text = "Loading..."
    this.BodyInputTarget.appendChild(option)

    const request = new FetchRequest("POST", this.bodiesUrlValue, { body: JSON.stringify({ meeting_import: { start_range: this.StartRangeInputTarget.value, end_range: this.EndRangeInputTarget.value } }), responseKind: "json" })

    const response = await request.perform()

    if (response.ok) {
      const responseJson = await response.json

      this.BodyInputTarget.innerHTML = null
      responseJson.forEach((body) => {
        const option = document.createElement("option")
        option.value = body
        option.text = body
        this.BodyInputTarget.appendChild(option)
      })
      this.BodyInputTarget.disabled = false

      if (this.selectedBody == null) {
        this.selectedBody = this.BodyInputTarget.value
      } else {
        this.BodyInputTarget.value = this.selectedBody
      }
    } else {
      const responseJson = await response.json

      alert("There was an error fetching bodies list: ", responseJson.error)
    }
  }

  storeBodyValue(e) {
    this.selectedBody = e.currentTarget.value
  }

  onResultClick(e) {
    e.preventDefault()

    this.showLoadingState()

    const url = new URL(e.currentTarget.href, window.location.origin)
    url.searchParams.set('format', 'turbo_stream')

    fetch(url, {
      headers: {
        'Accept': 'text/vnd.turbo-stream.html',
        'X-Requested-With': 'XMLHttpRequest'
      }
    }).then(response => {
      if (response.ok) {
        return response.text()
      } else {
        throw new Error(`Error: ${response.statusText}`)
      }
    }).then(data => {
      Turbo.renderStreamMessage(data)
    }).catch(error => {
      console.error('Error:', error)
    })

    window.history.pushState({}, '', url)
  }

  showLoadingState(e) {
    this.LoadingSpinnerTarget.classList.remove("hidden")
  }

}