import { Controller } from "@hotwired/stimulus"
import "select2"

export default class extends Controller {
  static get targets () {
    return [
      "ChevronUp",
      "ChevronDown",
      "Content",
      "HeadingContainer",
      "MinutesSummary",
      "SummaryResetButton",
      "VoteResult",
      "Status"
    ]
  }

  get amendmentController() {
    var elem = document.getElementById(`minutes-amendment-item-${this.itemId}`)
    return this.application.getControllerForElementAndIdentifier(elem, "minutes-amendment")
  }

  connect() {
    this.element[this.identifier] = this
  }

  initialize() {
    this.itemId          = this.data.get('itemId')
    this.formUrl           = this.data.get('formUrl')
    this.summaryOverridden = JSON.parse(this.data.get('summaryOverridden'))
    this.localStorageId    = `item-minutes-${this.itemId}-expanded`
    var storageExpanded    = JSON.parse(localStorage.getItem(this.localStorageId))
    this.expanded          = storageExpanded || false
    this.showAmendmentUi   = false

    this.updateExpandedStyle()
    this.summaryOverridden && this.updateResetButtonStyle()
    this.initSelect2()

    document.addEventListener("turbo:before-stream-render", this.onTurboStream.bind(this))
  }

  initSelect2() {
    if (this.hasVoteResultTarget) {
      $(this.VoteResultTarget).select2({
        selectionCssClass: ":all:",
        width: "100%"
      })

      $(this.VoteResultTarget).on("select2:select", this.saveVoteResult.bind(this))
    }
  }

  toggleExpanded(e) {
    this.expanded = !this.expanded
    localStorage.setItem(this.localStorageId, this.expanded)
    this.updateExpandedStyle()
  }

  updateExpandedStyle() {
    if (this.expanded == true) {
      this.ContentTarget.style.display = ""
      this.ChevronUpTarget.style.display = ""
      this.ChevronDownTarget.style.display = "none"
      this.HeadingContainerTarget.classList.add("bg-gray-200")
    } else {
      this.ContentTarget.style.display = "none"
      this.ChevronUpTarget.style.display = "none"
      this.ChevronDownTarget.style.display = ""
      this.HeadingContainerTarget.classList.remove("bg-gray-200")
    }
  }

  onFileCommentsLinkOnlyChange(e) {
    fetch(this.formUrl, {
      method: "PUT",
      credentials: "same-origin",
      headers:  {
        "Content-Type": "application/json",
        "Accept": "application/json"
      },
      body: JSON.stringify({ item: { minutes_file_comments_link_only: event.target.checked }})
    })
      .then(response => response.json())
      .then(response => {
        this.updateSummaryWithResponse(response)
      })
      .catch(console.error)
  }

  updateSummaryWithResponse(response) {
    this.updateSummaryOverridden(response)

    if (!response.minutes_summary_overridden) {
      this.MinutesSummaryTarget.innerHTML = response.minutes_summary_autogenerated
    }
  }

  updateSummaryOverridden(response) {
    this.summaryOverridden = response.minutes_summary_overridden
    this.updateResetButtonStyle()
  }

  updateResetButtonStyle(response) {
    if (response && response.minutes_summary_overridden) {
      this.summaryOverridden = true
      this.SummaryResetButtonTarget.classList.remove('hidden')
    } else {
      if (this.summaryOverridden) {
        this.SummaryResetButtonTarget.classList.remove('hidden')
      } else {
        this.SummaryResetButtonTarget.classList.add('hidden')
      }
    }
  }

  onSummaryReset(e) {
    fetch(this.formUrl, {
      method: "PUT",
      credentials: "same-origin",
      headers:  {
        "Content-Type": "application/json",
        "Accept": "application/json"
      },
      body: JSON.stringify({ item: { minutes_summary: null }})
    })
      .then(response => response.json())
      .then(response => {
        this.summaryOverridden = false
        this.updateSummaryWithResponse(response)
        this.updateResetButtonStyle(response)
      })
      .catch(console.error)
  }

  toggleAmendmentModal() {
    this.amendmentController.toggleAmendmentModal()
  }

  openAmendment(e) {
    this.amendmentController.openAmendment(e)
  }

  saveVoteResult(e) {
    var selectedOption = $(e.target).find("option:selected")
    var statusText = selectedOption.html()
    var status = selectedOption.data("status")

    fetch(this.formUrl, {
      method: "PUT",
      credentials: "same-origin",
      headers:  {
        "Content-Type": "application/json",
        "Accept": "application/json"
      },
      body: JSON.stringify({ item: { status, status_text: statusText }})
    })
      .then(response => response.json())
      .then(response => {
        this.StatusTarget.innerHTML = statusText
        this.updateSummaryWithResponse(response)
      })
      .catch(console.error)
  }

  onTurboStream(e) {
    this.replaceItemMinutesSummaryTemplate(e)
  }

  replaceItemMinutesSummaryTemplate(e) {
    let matchesFormat = e.target.outerHTML.match(/target=\"item_[\d]+_minutes_summary\"/g)
    if (matchesFormat && matchesFormat.length > 0) {
      e.preventDefault()

      let templateItemId = matchesFormat[0].match(/\d+/g)
      if (templateItemId && templateItemId.length > 0 && templateItemId[0] == this.itemId) {
        let parsedValue = this.parseTurboFragment(e.target)
        this.setMinutesSummaryHtml(parsedValue)
      }
    }
  }

  parseTurboFragment(elem) {
    var div = document.createElement('div');
    div.innerHTML = elem.outerHTML.trim();
    var template = div.getElementsByTagName("template")[0]
    var templateContent = template.content
    var inputs = Array.from(templateContent.querySelectorAll("input"))
    var input = inputs.find((i) => i.id.includes("item_minutes_summary"))
    return input.value
  }

  setMinutesSummaryHtml(html) {
    if (this.summaryOverridden == false) {
      var editor = this.MinutesSummaryTarget.editor
      var activeEditor = window.document.activeElement
      var activeEditorRange = activeEditor && activeEditor.editor && activeEditor.editor.getSelectedRange()

      editor.setSelectedRange([0, editor.getDocument().getLength()])
      editor.insertHTML(html)

      if (activeEditorRange) {
        activeEditor.editor.setSelectedRange(activeEditorRange)
      } else {
        activeEditor && activeEditor.focus && activeEditor.focus()
      }
    }
  }

}