import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

export default class extends Controller {

  static get targets() {
    return [
      "RejectModal", "Form", "ReasonInput", "ReasonOtherContainer", "ReasonOtherInput", "SendRejectionEmailInput", "SendRejectionEmailLabel"
    ]
  }

  initialize() {
    this.rejectPath = null
  }

  connect() {
    this.element[this.identifier] = this
    const select = new TomSelect(this.ReasonInputTarget)
    select.on("change", this.onReasonChange.bind(this))
  }

  onReasonChange(e) {
    if ((e.target && e.target.value === "Other") || e === "Other") {
      this.ReasonOtherContainerTarget.classList.remove("hidden")
    } else {
      if (this.hasReasonOtherContainerTarget) {
        this.ReasonOtherContainerTarget.classList.add("hidden")
      }
      $(this.ReasonOtherInputTarget).val("")
    }
  }

  showRejectModal(e) {
    this.rejectPath = e.target.dataset.rejectPath
    this.RejectModalTarget.classList.remove("hidden")
  }

  hideRejectModal() {
    this.RejectModalTarget.classList.add("hidden")
  }

  resetRejectModal() {
    $(this.ReasonInputTarget).val("Profanity")
    $(this.ReasonOtherInputTarget).val("")
    $(this.SendRejectionEmailInput).attr("checked", false)
  }

  submitRejectModal(e) {
    e.target.innerHTML = "Please wait..."
    e.target.disabled = true

    this.FormTarget.action = this.rejectPath
    this.FormTarget.requestSubmit()
  }

}